import React from "react";
import { Alert } from "react-bootstrap";

export default props =>
  props.formError ? (
    <Alert bsStyle="danger">
      <p>{props.formError}</p>
    </Alert>
  ) : props.formInformation ?  <Alert bsStyle="info">
  <p>{props.formInformation}</p>
</Alert> : null;
