import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./HandicapRules.css";

class HandicapRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      type: ""
    };
  }
  componentDidMount = async () => {
    try {
      var state = {
        
      };
      this.setState(state);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  render = () => {
    return (
      <section className="bg-light HandicapRules">
        <div>
          <form>
            <div>
              <h2>Handicap Rules</h2>
              <h4>Do not question the rules...</h4>
              <ol>
                <li>For new members, their society handicap shall be either their current club playing handicap rounded up or down to the nearest whole number or at a handicap up to 28 as determined by the Committee.</li>
                <li>For each round, a competition scratch score (CSS) shall be established as 36 stableford points provided at least 20% of the players score 30 or more stableford points. The CSS will be revised downwards to 34 stableford points (“revised CSS”) should this not be the case (i.e. a difficult course or weather conditions affecting scores).</li>
                <li>The winner of a competition round will have 2 shots taken off his society playing handicap plus any additional shots for stableford points above the CSS or revised CSS (whichever is applicable) according to Rule 8.</li>
                <li>The runner-up of a competition round will have 1 shot taken off his society playing handicap plus any additional shots for stableford points above the CSS or revised CSS (whichever is applicable) according to Rule 8.</li>
                <li>A player, scoring under 30 stableford points (or under 28 in a revised CSS) in a competition round, will receive 1 shot back on his society playing handicap.</li>
                <li>A player scoring from 30 to 36 stableford points (or 28 to 34 points in a revised CSS) in a competition round retains his existing society playing handicap.</li>
                <li>A player, scoring from 37 to 39 stableford points (or 35 to 37 points in a revised CSS) in a competition round will have 1 shot deducted from his society playing handicap.</li>
                <li>A player, scoring 40 stableford points or over (or 38 or over in a revised CSS) in a competition round will have 2 shots deducted from his society playing handicap.</li>
              </ol>
            </div>
          </form>
        </div>
      </section>
    );
  };
}

export default withRouter(HandicapRules);
