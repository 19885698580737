import React, { Component } from "react";
import { Table } from "react-bootstrap";
import PageHeader from "components/page-header/PageHeader";
import { APPEARANCES_CONTENT as PAGE_CONTENT } from "libs/content";
import * as API from "API";
import "./Appearances.css";

export default class Appearances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      appearances: []
    };
  }

  componentDidMount = async () => {
    this.fetchCompetitionAppearances();
  };

  fetchCompetitionAppearances = async (year) => {
    try {
      this.props.setLoading(true);
      const results = await API.appearances();
      this.props.setLoading(false);
      console.log(results);
      this.setState({ appearances: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  render = () => (
    <div className="Appearances">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <br />
      <Table striped bordered condensed>
        <tbody>
          {[{}].concat(this.state.appearances).map(
          (item, idx) =>
            idx !== 0 && item ? (
              <tr key={idx}>
                <td className="appearances-count">{item.appearances}</td>
                <td>
                  {[{}].concat(item.golfers).map(
                    (glfr, glfrIdx) =>
                    glfrIdx !== 0 && glfr ? (
                        <div key={glfrIdx}>{glfr.givenName + " "+glfr.familyName}</div>
                    ) : null
                  )}
                </td>
              </tr>
              ) : null
          )}
        </tbody>
      </Table>
    </div>
  );
}
