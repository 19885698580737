import React from "react";
import { ListGroupItem, ListGroup } from "react-bootstrap";
import "./EntityList.css";

const handleClick = (event, history) => {
  event.preventDefault();
  history.push(event.currentTarget.getAttribute("href"));
};

const createDefaultURL = (type, id) => {
  return "/" + type + "/" + id;
};

const renderList = props => {
  return [{}].concat(props.listItems).map(
    (item, i) =>
      i !== 0 ? (
        <ListGroupItem
          className={props.disabled ? "entity-disabled" : ""}
          key={item.id}
          href={
            props.formattedURL
              ? props.formattedURL(item.id)
              : createDefaultURL(props.type, item.id)
          }
          onClick={
            props.disabled
              ? e => e.preventDefault()
              : props.rowClick
                ? props.rowClick
                : e => handleClick(e, props.history)
          }
          header={props.header(item)}
        >
          {props.text(item)}
        </ListGroupItem>
      ) : props.hideAdd ? null : (
        <ListGroupItem
          className={props.disabled ? "entity-disabled" : ""}
          key="new"
          href={
            props.formattedAdd
              ? props.formattedAdd()
              : createDefaultURL(props.type, "new")
          }
          onClick={
            props.disabled
              ? e => e.preventDefault()
              : props.addClick
                ? props.addClick
                : e => handleClick(e, props.history)
          }
        >
          <h4>
            <b>{"\uFF0B"}</b> Add{" "}
            <span className="cap-entity">{props.type}</span>
          </h4>
        </ListGroupItem>
      )
  );
};

const EntityList = props => (
  <ListGroup className="EntityList">
    {!props.isLoading && renderList(props)}
  </ListGroup>
);

EntityList.defaultProps = { text: () => "" };

export default EntityList;
