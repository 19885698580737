import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Label
} from "recharts";
import "./RoundChart.css";

const widthBreak = 430;

const getFormattedData = props => {
  let formattedData = [];
  for (let hole of props.tee.holes) {
    const pts = hole[props.golfer.id].pts;
    formattedData.push({
      hole: hole.nbr,
      pts: pts,
      fill:
        pts === 5
          ? "#4267b2"
          : pts === 4
          ? "#76B041"
          : pts === 3
          ? "#bf69a5"
          : pts === 2
          ? "#fcb400"
          : pts === 1
          ? "#F56416"
          : ""
    });
  }
  return formattedData;
};

const getTicks = props => {
  let ticks = [0, 1, 2, 3, 4];
  for (let hole of props.tee.holes) {
    if (hole[props.golfer.id].pts === 5) {
      ticks.push(5);
      break;
    }
  }
  return ticks;
};

const getMargin = () => {
  let margin = { top: 5, right: 15, left: 0, bottom: 5 };
  if (window.outerWidth < widthBreak) {
    margin = { top: 5, right: 15, left: -30, bottom: 5 };
  }
  return margin;
};

export default props => (
  <div className="RoundChart">
    {props.noHeading && window.outerWidth >= widthBreak ? null : (
      <div className="chart-title">
        {props.heading ? props.heading : "Pts. per Hole"}
      </div>
    )}
    <ComposedChart
      width={props.width}
      height={200}
      data={getFormattedData(props)}
      margin={getMargin()}
    >
      <XAxis ticks={[3, 6, 9, 12, 15, 18]} dataKey="hole" />
      <YAxis
        yAxisId="2"
        tickLine={false}
        domain={["dataMin", "dataMax +"]}
        ticks={getTicks(props)}
      >
        {window.outerWidth >= widthBreak ? (
          <Label
            angle={270}
            position="left"
            offset={-10}
            style={{ textAnchor: "middle" }}
          >
            Stableford Pts.
          </Label>
        ) : null}
      </YAxis>
      <CartesianGrid strokeDasharray="3 3" />
      <Bar
        name="Stableford Pts."
        yAxisId="2"
        dataKey="pts"
        barSize={8}
        fill="#4267b2"
      />
    </ComposedChart>
  </div>
);
