import React, { Component } from "react";
import { Table, Grid, Col, Row, Breadcrumb } from "react-bootstrap";
import FormRow from "components/form-row/FormRow";
import LoaderButton from "components/loader-button/LoaderButton";
import RoundPie from "components/round-pie/RoundPie";
import RoundChart from "components/round-chart/RoundChart";
import "./GolferScores.css";

class GolferScores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizeUpdater: this.updateChartWidth.bind(this)
    };
  }

  componentDidMount() {
    const chartWidth =
      document.getElementsByClassName("chart-container").length > 0
        ? document.getElementsByClassName("chart-container")[0].offsetWidth - 30
        : 0;

    this.setState({
      chartWidth: chartWidth
    });
    window.addEventListener("resize", this.state.resizeUpdater);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  updateChartWidth = () => {
    const chartWidth =
      document.getElementsByClassName("chart-container").length > 0
        ? document.getElementsByClassName("chart-container")[0].offsetWidth - 30
        : 0;
    this.setState({
      chartWidth: chartWidth
    });
  };

  autoFocus = (nbr, value) => {
    if (value === "0" || value > 1) {
      const next = document.getElementById("hole_" + (nbr * 1 + 1));
      if (next) {
        next.focus();
      }
    }
  };

  renderHoleTable = nine => (
    <Table
      striped
      bordered
      condensed
      className={(this.props.static ? "static " : "") + "holes-table"}
    >
      <thead>
        <tr>
          <th className="hole-desc-col">Hole</th>
          <th className="hole-score-col">Score</th>
          <th className="hole-pts-col">Pts</th>
        </tr>
      </thead>
      <tbody>
        {this.renderHoles(nine)}
        <tr className="total-row" key={"hole-sub-total"}>
          <td className="hole-desc-col">
            {nine === "front" ? "FRONT" : "BACK"}
          </td>
          <td className="hole-score-col">
            {this.props.totals(this.props.golfer, "score", nine)}
          </td>
          <td className="hole-pts-col">
            {this.props.totals(this.props.golfer, "pts", nine)}
          </td>
        </tr>
        {nine === "back" ? (
          <tr className="total-row" key={"hole-total"}>
            <td className="hole-desc-col">TOTAL</td>
            <td className="hole-score-col">
              {this.props.totals(this.props.golfer, "score", "total")}
            </td>
            <td className="hole-pts-col">
              {this.props.totals(this.props.golfer, "pts", "total")}
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );

  renderHoles = nine =>
    [{}].concat(this.props.tee.holes).map((hole, i) =>
      (i !== 0 && i < 10 && nine === "front") ||
      (i !== 0 && i > 9 && nine === "back") ? (
        <tr key={hole.nbr}>
          <td className="hole-desc-col">
            <div className="hole-desc">
              {hole.nbr + " - Par " + hole.par + " - SI " + hole.si}
            </div>
            <div>{hole.len + " " + this.props.course.measuredIn}</div>
          </td>
          <td className="hole-score-col">
            {this.props.static ? (
              hole[this.props.golfer.id] ? (
                <div
                  className={
                    "score-indicator" +
                    " " +
                    (hole[this.props.golfer.id]
                      ? hole[this.props.golfer.id].pts === 5
                        ? "albatross"
                        : hole[this.props.golfer.id].pts === 4
                        ? "eagle"
                        : hole[this.props.golfer.id].pts === 3
                        ? "birdie"
                        : hole[this.props.golfer.id].pts === 2
                        ? "par"
                        : hole[this.props.golfer.id].pts === 1
                        ? "bogey"
                        : hole[this.props.golfer.id].pts === 0
                        ? "double-bogey"
                        : ""
                      : "")
                  }
                >
                  {hole[this.props.golfer.id].score}
                </div>
              ) : (
                ""
              )
            ) : (
              <FormRow
                autoFocus={i === 1}
                type="number"
                controlId={"hole_" + hole.nbr}
                errors={this.props.errors}
                value={
                  hole[this.props.golfer.id]
                    ? hole[this.props.golfer.id].score
                    : ""
                }
                onChange={e => {
                  this.props.onChange(
                    hole.nbr,
                    this.props.golfer,
                    e.target.value
                  );
                  this.autoFocus(hole.nbr, e.target.value);
                }}
                onFocus={this.props.onFocus}
                onBlur={e => this.props.onBlur(hole.nbr, this.props.golfer)}
                min={1}
                max={20}
              />
            )}
          </td>
          <td className="hole-pts-col">
            {hole[this.props.golfer.id] ? hole[this.props.golfer.id].pts : ""}
          </td>
        </tr>
      ) : null
    );

  render() {
    return (
      <div className="GolferScores">
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.props.homeClick}>
            {this.props.homeName}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="golfer-breadcrumb">
            {this.props.name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <form>
          <Grid className="holes-container">
            {this.props.showCharts ? (
              <Row className="show-charts">
                <Col md={6} className="chart-container">
                  <RoundPie
                    noHeading={true}
                    width={this.state.chartWidth}
                    {...this.props}
                  />
                </Col>
                <Col md={6}>
                  <RoundChart
                    noHeading={true}
                    width={this.state.chartWidth}
                    {...this.props}
                  />
                </Col>
              </Row>
            ) : null}
            <Row className="show-grid">
              <Col md={6}>{this.renderHoleTable("front")}</Col>
              <Col md={6}>{this.renderHoleTable("back")}</Col>
            </Row>
          </Grid>
          {!this.props.static ? (
            <LoaderButton
              block
              bsStyle="primary"
              className="confirm-golfer"
              bsSize="large"
              onClick={e => this.props.confirm()}
              disabled={this.props.disabled}
              isLoading={this.props.isLoading}
              text={"Save Scores"}
              loadingText="Saving…"
            />
          ) : null}
        </form>
      </div>
    );
  }
}

export default GolferScores;
