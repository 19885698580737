import React from "react";
import { Table } from "react-bootstrap";
import greenJacket from "img/green-jacket.png";
import orangeJacket from "img/orange-jacket.png";
import "./AggregatedCompetitionStats.css";

export default props => (
  <div className="AggregatedCompetitionStats">
    <h4>{props.heading ? props.heading : "My Competition Stats"}</h4>
    <Table striped bordered condensed className="basic-stats">
      <tbody>
        <tr>
          <td>
            <span className="fas fa-list-ol" /> Appearances
          </td>
          <td>{props.data.noOfComps}</td>
        </tr>
        <tr>
          <td>
            <img src={greenJacket} alt={"Green Jacket"} className="jacket-icon" /> Wins
          </td>
          <td>{props.data.wins}</td>
        </tr>
        <tr>
          <td>
            <span className="fas fa-trophy silver" /> Runners Up
          </td>
          <td>{props.data.runnersUp}</td>
        </tr>
        <tr>
          <td>
            <img src={orangeJacket} alt={"Orange Jacket"} className="jacket-icon" /> Last Places
          </td>
          <td>{props.data.last}</td>
        </tr>
        <tr>
          <td>
          <span className="fas fa-medal gold" />SGS Trophy Record
          </td>
          <td>
            <div>Matches: {props.data.ryderCupMatches}</div>
            <div>Won: {props.data.ryderCupMatchWins} ({Math.round((props.data.ryderCupMatchWins/props.data.ryderCupMatches)*100)}%)</div>
            <div>Lost: {props.data.ryderCupMatchLosses} ({Math.round((props.data.ryderCupMatchLosses/props.data.ryderCupMatches)*100)}%)</div>
            <div>Halved: {props.data.ryderCupMatchHalves} ({Math.round((props.data.ryderCupMatchHalves/props.data.ryderCupMatches)*100)}%)</div>
            <div>Points: {props.data.ryderCupMatchPoints}</div>
            {false?<div>Total Points: {props.data.ryderCupTotalPoints}</div>:null}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
);
