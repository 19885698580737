import React, { Component } from "react";
import { Auth } from "aws-amplify";
import * as API from "API";
import { getLoggedInEmail } from "libs/awsLib";
import config from "./config";
import { NavLink, withRouter } from "react-router-dom";
import Routes from "Routes";
import SideNavLink from "components/side-nav-link/SideNavLink";
import PageLoader from "components/page-loader/PageLoader";
import PageError from "components/page-error/PageError";
import Offline from "components/offline/Offline";
import SWUpdate from "components/sw-update/SWUpdate";
import { isInAdminGroup } from "libs/awsLib";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authentication from "reducers/authentication";
import * as utility from "reducers/utility";
import { GENERIC_CONTENT as PAGE_CONTENT } from "libs/content";
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import "App.css";
import bg from "img/header-bg3.jpg";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialising: true,
      backOnline: false,
      topMenuItems : [
        {
          name: "Home",
          to: "/",
          icon: "fas fa-home"
        }
      ],
      menuItems: [
        {
          name: "Appearances",
          to: "/appearances",
          icon: "fas fa-list-ol"
        },
        {
          name: "Golfers",
          to: "/golfers",
          icon: "fas fa-golf-ball",
          admin: true,
        },
        {
          name: "Courses",
          to: "/courses",
          icon: "fas fa-tree",
          admin: true,
        },
        {
          name: "News",
          to: "/news",
          icon: "fas fa-newspaper",
          admin: true,
        },
        {
          name: "Competitions",
          to: "/competitions",
          icon: "fas fa-trophy"
        },
        {
          name: "Rounds",
          to: "/rounds",
          icon: "fas fa-map-marker-alt"
        },
        {
          name: "Handicaps",
          to: "/handicaps",
          icon: "fas fa-universal-access"
        },
        {
          name: "Honours",
          to: "/honours",
          icon: "fas fa-medal"
        },
        {
          name: "Gallery",
          to: "/gallery",
          icon: "fas fa-images"
        },
        {
          name: "Committee",
          to: "/committee",
          icon: "fas fa-users"
        },
      ],
      extraMenuItems: [
        {
          name: "My Profile",
          to: "/my-profile",
          icon: "fas fa-user-circle"
        },
        {
          name: "Logout",
          to: "/login",
          icon: "fas fa-sign-out-alt",
          onClick: this.handleLogout
        },
      ],
    };
  }
  componentDidMount = async () => {
    try {
      this.registerServiceWorker();
      this.loadFacebookSDK();
      this.detectOnline();
      if (await Auth.currentAuthenticatedUser()) {
        const isAdminPromise = isInAdminGroup();

        const email = await getLoggedInEmail();
        const currentUserPromise = API.getGolferByEmail(email);

        const isAdmin = await isAdminPromise;
        const currentUser = await currentUserPromise;

        this.props.setAdmin(isAdmin);

        this.props.setCurrentUser(
          currentUser && currentUser.length > 0 ? currentUser[0] : null
        );

        this.props.setAuthenticated(true);
      }
      this.setState({ initialising: false });
    } catch (e) {
      console.log(e);
      this.setState({ initialising: false });
    }
    this.props.setAuthenticating(false);
  };

  registerServiceWorker = () => {
    serviceWorkerRegistration.register({
      onUpdate: registration => {
        const waitingServiceWorker = registration.waiting
    
        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener("statechange", event => {
            if (event.target.state === "activated") {
              this.props.setSWUpdate(true);
              setTimeout(() => window.location.reload(), 3000);
            }
          });
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      }
    });
  }

  detectOnline = () => {
    window.addEventListener(
      "offline",
      (e) => {
        this.props.setOnline(false);
      },
      false
    );

    window.addEventListener(
      "online",
      (e) => {
        this.props.setOnline(true);
        this.setState({ backOnline: true });
        setTimeout(() => this.setState({ backOnline: false }), 4000);
      },
      false
    );
  };

  loadFacebookSDK = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: config.social.FB,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v3.1",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  handleLogout = (event) => {
    Auth.signOut();
    this.props.setAuthenticated(false);
    this.props.setAdmin(false);
    this.props.setCurrentUser(null);
    this.props.history.push("/");
  };

  handleMenuToggle = (event) => {
    this.props.setMenuOpen(!this.props.menuOpen);
  };

  closeMenu = (event) => {
    this.props.setMenuOpen(false);
  };

  sortMenu = (menu) => {
    return menu.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  };

  renderMenuItems = (menu) =>
    [{}]
      .concat(menu)
      .map((item, idx) =>
        idx !== 0 && (!item.admin || this.props.isAdmin) ? (
          <SideNavLink
            key={"menu-" + idx}
            name={item.name}
            to={item.to}
            icon={item.icon}
            hr={item.hr}
            onClick={item.onClick ? item.onClick : this.handleMenuToggle}
          />
        ) : null
      );
  render = () =>
    this.state.initialising ? (
      <div className="page-loading">
        {" "}
        <PageLoader message="initialising" />
      </div>
    ) : (
      !this.props.isAuthenticating && (
        <div className="App">
          {this.props.isAuthenticated ? (
            <div>
              {!this.props.isOnline || this.state.backOnline ? (
                <Offline backOnline={this.state.backOnline} />
              ) : null}
              {this.props.isSWUpdate ? (
                <SWUpdate />
              ) : null}
              <div className="nav-side-menu">
                <div className="main-app-name">
                  <NavLink
                    onClick={this.closeMenu}
                    exact={true}
                    activeClassName="active"
                    to="/"
                    className="brand"
                  >
                  Shakespeare Golf Society
                  </NavLink>
                </div>
                <i
                  className="fas fa-bars fa-2x toggle-btn"
                  data-toggle="collapse"
                  data-target="#menu-content"
                  onClick={this.handleMenuToggle}
                />
                <div className="menu-list">
                  <ul
                    id="menu-content"
                    className={
                      "menu-content collapse " +
                      (this.props.menuOpen ? "in" : "out")
                    }
                  >
                    {this.renderMenuItems(this.sortMenu(this.state.topMenuItems))}
                    {this.renderMenuItems(this.sortMenu(this.state.menuItems))}
                    {this.renderMenuItems(this.state.extraMenuItems)}
                  </ul>
                </div>
              </div>
              <div
                id="main"
                className={
                  "container" +
                  (this.props.isLoading ? " page-loading" : "") +
                  (this.props.hasError ? " page-error" : "")
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    {this.props.isLoading ? <PageLoader /> : null}
                    {this.props.hasError ? <PageError /> : null}
                    <div className="page-content">
                      <Routes childProps={this.props} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div id="bg">
                <img src={bg} alt="lovely-golf-course" />
              </div>
              <div className="un-authenticated-left-hand">
                <div className="main-app-name">
                  <a className="active" aria-current="true" href="/">
                  Shakespeare Golf Society
                  </a>
                </div>
                <h1 className="seo-heading">{PAGE_CONTENT.LANDING_SUB_HEADING}</h1>
                <div className="left-hand-content">
                  {PAGE_CONTENT.LANDING_TEXT}
                </div>
              </div>
              <div className="un-authenticated-container container">
                <div className="main-app-name">
                  <NavLink to="/">
                  Shakespeare Golf Society
                  </NavLink>
                </div>
                <Routes childProps={this.props} />
              </div>
              <footer className="navbar navbar-fixed-bottom home-footer">
                <a target="_blank" href="/cookie-and-privacy-policy.html">
                  Cookie &amp; Privacy Policy
                </a>
              </footer>
            </div>
          )}
        </div>
      )
    );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  isAuthenticating: state.authentication.isAuthenticating,
  isAdmin: state.authentication.isAdmin,
  currentUser: state.authentication.currentUser,
  isLoading: state.utility.isLoading,
  hasError: state.utility.hasError,
  menuOpen: state.utility.menuOpen,
  isOnline: state.utility.online,
  isSWUpdate: state.utility.isSWUpdate
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuthenticated: authentication.setAuthenticated,
      setAuthenticating: authentication.setAuthenticating,
      setAdmin: authentication.setAdmin,
      setCurrentUser: authentication.setCurrentUser,
      setLoading: utility.setLoading,
      setError: utility.setError,
      setMenuOpen: utility.setMenuOpen,
      setOnline: utility.setOnline,
      setSWUpdate: utility.setSWUpdate
    },
    dispatch
  ); 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App)); 

