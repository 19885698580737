import { invokeApig } from "../libs/awsLib";

const apiName = "competition";

// Competition API
export const competitions = () =>
  invokeApig({
    endpoint: apiName,
    path: "/competition"
  });
export const appearances = () =>
  invokeApig({
    endpoint: apiName,
    path: "/competition/appearances"
  });
export const competitionsLite = () =>
  invokeApig({
    endpoint: apiName,
    path: "/competition",
    queryParams: { partial: true }
  });
export const competitionsLiteByYear = compYear =>
  invokeApig({
    endpoint: apiName,
    path: "/competition",
    queryParams: { partial: true, compYear: compYear }
  });
export const getCompetition = id =>
  id !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/competition/${id}`
      })
    : {};
export const saveCompetition = (id, competition) =>
  invokeApig({
    endpoint: apiName,
    path: `/competition/${id}`,
    method: "PUT",
    body: competition
  });
export const createCompetition = competition =>
  invokeApig({
    endpoint: apiName,
    path: "/competition",
    method: "POST",
    body: competition
  });
export const deleteCompetition = id =>
  invokeApig({
    endpoint: apiName,
    path: `/competition/${id}`,
    method: "DELETE"
  });
