export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "shakespearegolf-photos"
  },
  apiGateway: {
    COMPETITION_URL: "https://8qndn3431i.execute-api.eu-west-1.amazonaws.com/prod",
    CONTENT_URL: "https://hueijmcw2f.execute-api.eu-west-1.amazonaws.com/prod",
    COURSE_URL: "https://olu8kofdp1.execute-api.eu-west-1.amazonaws.com/prod",
    GOLFER_URL: "https://j0gej0p4mf.execute-api.eu-west-1.amazonaws.com/prod",
    HANDICAP_URL: "https://a2jj3qzpu0.execute-api.eu-west-1.amazonaws.com/prod",
    PHOTOS_URL: "https://bc4c7gv33c.execute-api.eu-west-1.amazonaws.com/prod",
    ROUND_URL: "https://vh8yf3rtf5.execute-api.eu-west-1.amazonaws.com/prod",
    SCORES_URL: "https://jqlzr7hzce.execute-api.eu-west-1.amazonaws.com/prod",
    ADMIN_URL: "https://lny8cvx4dg.execute-api.eu-west-1.amazonaws.com/prod",
    REGION: "eu-west-1"
  },
  cognito: {
    USER_POOL_ID: "eu-west-1_5TLWb6BcP",
    APP_CLIENT_ID: "520n6lj4cirgiulvjpjqm5tncl",
    REGION: "eu-west-1",
    IDENTITY_POOL_ID: "eu-west-1:9e54631f-ac01-4515-b0fa-466e48e9b03b"
  },
  social: {
    FB: "1718397488727582"
  }
};
