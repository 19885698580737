import React, { Component } from "react";
import * as API from "API";
import { Row, Col } from "react-bootstrap";
import News from "components/news/News";
import HandicapChart from "components/handicap-chart/HandicapChart";
import BadgesList from "components/badges-list/BadgesList";
import ReigningChampions from "components/reigning-champions/ReigningChampions";
import RyderCupHonoursBoard from "components/ryder-cup-honours-board/RyderCupHonoursBoard";
import AggregatedRoundStats from "components/aggregated-round-stats/AggregatedRoundStats";
import AggregatedCompetitionStats from "components/aggregated-competition-stats/AggregatedCompetitionStats";
import { Link } from "react-router-dom";
import { getLoggedInEmail } from "libs/awsLib";
import { withRouter } from "react-router-dom";
import { formatHandicapListForGolfer } from "libs/handicapUtils";
import { formatRoundStats } from "libs/roundStatsHelper";
import Photos from "components/photos/Photos";
import "./Home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      givenName: "",
      handicap: "",
      memberStatus: false,
      competitions: [],
      roundHandicaps: [],
      roundStats: { noOfRounds: 1 },
      competitionStats: {},
      resizeUpdater: this.updateChartWidth.bind(this)
    };
  }
  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const competitionsPromise = API.competitionsLite();
      const roundHandicapsPromise = API.handicaps();
      const golferResp = await this.getGolfer(this.props.match.params.id);

      const golferDetailsPromise = API.golferDetails(golferResp.id);

      const competitionsResp = await competitionsPromise;
      let roundHandicaps = await roundHandicapsPromise;
      const golferDetailsResp = await golferDetailsPromise;
      let news;

      const filteredList = formatHandicapListForGolfer(
        golferResp.id,
        roundHandicaps
      );

      if (golferDetailsResp.stats && !this.props.match.params.id) {
        news = await API.content("news");
      }

      this.props.setLoading(false);

      const chartWidth =
        document.getElementsByClassName("full-section").length > 0
          ? document.getElementsByClassName("full-section")[0].offsetWidth - 30
          : 0;

      const golfer = {
        pathId: this.props.match.params.id,
        id: golferResp.id || "",
        email: golferResp.email || "",
        givenName: golferResp.givenName || "",
        familyName: golferResp.familyName || "",
        handicap: golferResp.handicap || "",
        competitions: competitionsResp,
        roundStats: golferDetailsResp.stats
          ? golferDetailsResp.stats.roundStats
          : null,
        competitionStats: golferDetailsResp.stats
          ? golferDetailsResp.stats.competitionStats
          : null,
        roundHandicaps: filteredList,
        chartWidth: chartWidth,
        badges: golferDetailsResp.badges,
        news: news
      };
      console.log(golfer);
      this.setState(golfer);

      window.addEventListener("resize", this.state.resizeUpdater);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  updateChartWidth = () => {
    const chartWidth =
      document.getElementsByClassName("full-section").length > 0
        ? document.getElementsByClassName("full-section")[0].offsetWidth - 30
        : 0;
    this.setState({
      chartWidth: chartWidth
    });
  };

  getFilteredComps = comps => {
    if(comps === null){
      return null;
    }
    const latestComps = [
      comps.find(itm => itm.compType === "SGS" && itm.winner) 
    ];
    return latestComps[0];
  };

  getGolfer = async id => {
    if (!id) {
      const email = await getLoggedInEmail();
      const response = await API.getGolferByEmail(email);
      return response && response.length > 0 ? response[0] : { noGolfer: true };
    } else {
      return API.getGolfer(id);
    }
  };

  filterBadges = best => {
    let filtered = this.state.badges.filter(item => item.earned);
    return best ? this.sortBadges(filtered) : filtered;
  };

  formatRoundStats = () => {
    return formatRoundStats({ ...this.state.roundStats });
  };

  sortBadges = result => {
    // Returns newest first
    return result.sort((a, b) => {
      const rankA = this.getBadgeRank(a),
        rankB = this.getBadgeRank(b);
      return rankA > rankB ? 1 : rankA < rankB ? -1 : 0;
    });
  };

  getBadgeRank = badge => {
    return badge.type === "rare"
      ? 1
      : badge.tier === 1 || badge.type === "win"
      ? 2
      : !badge.tier && !badge.bad
      ? 3
      : badge.tier === 2
      ? 4
      : badge.tier === 3
      ? 5
      : !badge.bad === 3
      ? 6
      : 7;
  };

  render = () => (
    <div className="Home">
      {this.state.roundStats || this.state.pathId ? (
        <div>
          <h3 className="GolferTitle">
            {this.state.givenName + " " + this.state.familyName}
            <span className="handicap-title-dash"> - </span>
            <Link className="handicap-title" to={"/handicaps/" + this.state.id}>
              {this.state.handicap} HCP
            </Link>
          </h3>
          {this.state.roundStats &&
          this.state.roundStats.noOfRounds &&
          this.state.roundStats.noOfRounds > 0 ? (
            <div>
              <Row className="full-section">
                <Col className="left-section col-xl-6">
                  {this.state.pathId ? null : <News items={this.state.news} />}
                  <AggregatedRoundStats
                    data={this.formatRoundStats()}
                    golferId={this.state.id}
                  />
                </Col>
                <Col className="right-section col-xl-6">
                  <hr className="first-line" />
                  {/* <RyderCupHonoursBoard
                    compTypeName="SGS Trophy"
                    compType="SGS"
                    comps={this.state.competitions}
                  />
                  <hr /> */}
                  <AggregatedCompetitionStats
                    data={this.state.competitionStats}
                  />
                  
                  {this.state.badges ? (
                    <div>
                      <hr />
                      <BadgesList
                        badges={this.filterBadges(false)}
                        golferId={this.state.id}
                        heading={"My Badges"}
                        limit={100}
                      />
                     </div>
                  ) : null}  
                  {this.getFilteredComps(this.state.competitions) ? 
                  <div>     
                    <hr />
                    <ReigningChampions comp={this.getFilteredComps(this.state.competitions)} />  
                  </div> : null }            
                </Col>
              </Row>
              {this.state.roundHandicaps.length > 1 ? (
                <Row>
                  <Col className="col-md-12">
                    <hr />
                    <div>
                      <HandicapChart
                        heading={"Handicap"}
                        data={this.state.roundHandicaps}
                        width={this.state.chartWidth}
                        golferId={this.state.id}
                        showAllLink={true}
                      />
                    </div>
                  </Col>
                </Row>
                ) : null}
            </div>
          ) : (
            <h4>No stats to display.</h4>
          )}
        </div>
      ) : (
        <Photos
          folder="gallery"
          showUpload={
            this.props.currentUser && this.props.currentUser.memberStatus
              ? true
              : false
          }
        />
      )}
    </div>
  );
}

export default withRouter(Home);
