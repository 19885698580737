import React from "react";
import { PieChart, Legend, Pie, Tooltip } from "recharts";
import "./RoundPie.css";

const widthBreak = 430;

const getFormattedData = props => {
  let albatros = 0,
    eagle = 0,
    birdie = 0,
    par = 0,
    bogey = 0,
    double = 0;
  for (let hole of props.tee.holes) {
    if (hole[props.golfer.id].pts === 5) {
      albatros = albatros + 1;
    } else if (hole[props.golfer.id].pts === 4) {
      eagle = eagle + 1;
    } else if (hole[props.golfer.id].pts === 3) {
      birdie = birdie + 1;
    } else if (hole[props.golfer.id].pts === 2) {
      par = par + 1;
    } else if (hole[props.golfer.id].pts === 1) {
      bogey = bogey + 1;
    } else if (hole[props.golfer.id].pts === 0) {
      double = double + 1;
    }
  }
  let data = [
    { name: "Albatros", value: albatros, fill: "#4267b2" },
    { name: "Eagle", value: eagle, fill: "#76B041" },
    { name: "Birdie", value: birdie, fill: "#bf69a5" },
    { name: "Par", value: par, fill: "#fcb400" },
    { name: "Bogey", value: bogey, fill: "#F56416" },
    { name: "Double Bogey", value: double, fill: "#EF271B" }
  ].filter(item => item.value !== 0);

  return data;
};

export default props => (
  <div className="RoundPie">
    {props.noHeading && window.outerWidth >= widthBreak ? null : (
      <div className="chart-title">
        {props.heading ? props.heading : "Scoring breakdown"}
      </div>
    )}
    <PieChart width={props.width} height={150}>
      <Legend align="right" layout="vertical" verticalAlign="middle" />
      <Pie
        data={getFormattedData(props)}
        dataKey="value"
        label={false}
        fill="#82ca9d"
      />
      <Tooltip />
    </PieChart>
  </div>
);
