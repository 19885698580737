import React, { Component } from "react";
import * as API from "API";
import { Row, Col } from "react-bootstrap";
import RyderCupHonoursBoard from "components/ryder-cup-honours-board/RyderCupHonoursBoard";
import HonoursBoard from "components/honours-board/HonoursBoard";
import PageHeader from "components/page-header/PageHeader";
import { HONOURS_CONTENT as PAGE_CONTENT } from "libs/content";
import greenJacket from "img/green-jacket.png";
import orangeJacket from "img/orange-jacket.png";
import "./Honours.css";

export default class Honours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      competitions: []
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.competitionsLite();
      this.props.setLoading(false);
      this.setState({ competitions: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  render = () => (
    <div className="Honours">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <Row>
        <Col className="left-section col-xl-6">
            <RyderCupHonoursBoard
            compTypeName="SGS Trophy"
            compType="SGS"
            comps={this.state.competitions}
          />
        </Col>
        <Col className="right-section col-xl-6">
          <HonoursBoard
            compTypeName="Green Jacket"
            compType="SGS"
            icon={<img src={greenJacket} alt={"Green Jacket"} className="jacket-icon" />}
            comps={this.state.competitions}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-xl-6">
          <HonoursBoard
            compTypeName="Orange Jacket"
            compType="SGS"
            overrideWinner="lastPlace"
            icon={<img src={orangeJacket} alt={"Orange Jacket"} className="jacket-icon" />}
            comps={this.state.competitions}
          />
        </Col>
        <Col className="col-xl-6">
          <HonoursBoard
            compTypeName="Shit Shirt"
            compType="SGS"
            overrideWinner="shitShirtWinner"
            icon={<span className={"fas fa-tshirt nav-header-icon"} />}
            comps={this.state.competitions}
          />
        </Col>
      </Row>
    </div>
  );
}
