import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./RyderCupHonoursBoard.css";

const getOverallWinLoseOrDrawClass = (comps, name) => {
  let hookersScore = 0, swingersScore = 0, winLoseOrDraw = "";

  for(let comp of comps){
    if(comp.ryderCupSummary){
      let hookersComp = comp.ryderCupSummary.find( ({ name }) => name === 'Hookers' );
      let swingersComp = comp.ryderCupSummary.find( ({ name }) => name === 'Swingers' );

      if(hookersComp !== undefined && swingersComp !== undefined){
        let hookersCompScore = hookersComp.ryderCupTotal;
        let swingersCompScore = swingersComp.ryderCupTotal;
    
        if(hookersCompScore > swingersCompScore){
          hookersScore = hookersScore+1;
        }
        else if(swingersCompScore > hookersCompScore){
          swingersScore = swingersScore+1;
        }
        else{
          hookersScore = hookersScore+0.5;
          swingersScore = swingersScore+0.5;
        }
      }
      
    }
  }

  if(name==="Hookers"){
    winLoseOrDraw = hookersScore>swingersScore?"winner":swingersScore>hookersScore?"loser":"draw";
  }
  else{
    winLoseOrDraw = hookersScore>swingersScore?"loser":swingersScore>hookersScore?"winner":"draw";
  }

  return {winLoseOrDraw: winLoseOrDraw, hookersScore: hookersScore, swingersScore: swingersScore};
};

const getWinLoseOrDrawClass = (item, name) => {
  let winLoseOrDraw = "";
  const hookers = item.ryderCupSummary.find( ({ name }) => name === 'Hookers' );
  const swingers = item.ryderCupSummary.find( ({ name }) => name === 'Swingers' );
  if(hookers !== undefined && swingers !== undefined){
    const hookersScore = hookers.ryderCupTotal;
    const swingersScore = swingers.ryderCupTotal;
    if(name==="Hookers"){
      winLoseOrDraw = hookersScore>swingersScore?"winner":swingersScore>hookersScore?"loser":"draw";
    }
    else{
      winLoseOrDraw = hookersScore>swingersScore?"loser":swingersScore>hookersScore?"winner":"draw";
    }
  }
  return winLoseOrDraw;
};

export default props => (
  <div className="RyderCupHonoursBoard">
    <h3><span className={"fas fa-trophy gold nav-header-icon"} /> SGS Trophy</h3>
    <Table striped bordered condensed>
      <thead>
        <tr>
          <th className="year-col">Year</th>
          <th className="name-col">Hookers</th>
          <th className="name-col">Swingers</th>
        </tr>
      </thead>
      <tbody>
        {[{}]
          .concat(props.comps.filter(comp => comp.compType === props.compType))
          .map(
            (item, idx) =>
              idx !== 0 && item.ryderCupSummary 
                && item.ryderCupSummary.find( ({ name }) => name === 'Hookers' ) !== undefined 
                && item.ryderCupSummary.find( ({ name }) => name === 'Swingers' ) !== undefined 
                ? (
                <tr key={idx}>
                  <td>
                    <Link to={"/competition/summary/" + item.id}>
                      {item.dateFrom.substring(0, 4)}
                    </Link>
                  </td>
                  <td className={"team-col hookers "+ getWinLoseOrDrawClass(item, "Hookers")}>
                    {item.ryderCupSummary.find( ({ name }) => name === 'Hookers' ).ryderCupTotal}
                  </td>
                  <td className={"team-col swingers "+ getWinLoseOrDrawClass(item, "Swingers")}>
                    {item.ryderCupSummary.find( ({ name }) => name === 'Swingers' ).ryderCupTotal}
                  </td>
                </tr>
              ) : null
          )}
          <tr>
            <td className="overall">
              Overall
            </td>
            <td className={"team-col overall hookers "+ getOverallWinLoseOrDrawClass(props.comps, "Hookers").winLoseOrDraw}>
              {getOverallWinLoseOrDrawClass(props.comps, "Hookers").hookersScore}
            </td>
            <td className={"team-col overall swingers "+ getOverallWinLoseOrDrawClass(props.comps, "Swingers").winLoseOrDraw}>
              {getOverallWinLoseOrDrawClass(props.comps, "Swingers").swingersScore}
            </td>
          </tr>
      </tbody>
    </Table>
  </div>
);
