export function getHandicapCat(handicap) {
  handicap = handicap * 1;
  return handicap <= 5.4
    ? 1
    : handicap <= 12.4
      ? 2
      : handicap <= 20.4
        ? 3
        : handicap <= 28.4
          ? 4
          : handicap <= 36.4
            ? 5
            : handicap <= 54
              ? 6
              : 7;
}

export function getHandicapBuffer(handicap) {
  handicap = handicap * 1;
  return handicap <= 5.4
    ? 1
    : handicap <= 12.4
      ? 2
      : handicap <= 20.4
        ? 3
        : handicap <= 28.4
          ? 4
          : handicap <= 36.4
            ? 4
            : handicap <= 54
              ? 4
              : 4;
}

export function getHandicapCatChange(handicap) {
  const cat = getHandicapCat(handicap);
  return cat / 10;
}

export const formatHandicapListForGolfer = (golferId, roundHandicaps) => {
  let roundHandicapsList = [],
    filteredList = [];
  if (roundHandicaps) {
    for (let i = 0; i < roundHandicaps.length; i++) {
      let current = roundHandicaps[i].golfers[golferId];
      if (current) {
        roundHandicapsList.push(current);
      }
    }
  }
  for (let j = 0; j < roundHandicapsList.length; j++) {
    let current = roundHandicapsList[j],
      previous = roundHandicapsList[j + 1];
    if (previous && previous.newHandicap !== current.handicap) {
      current.manualAdjustment =
        Math.round((current.handicap - previous.newHandicap) * 10) / 10;
    }
  }

  if (roundHandicaps) {
    for (let i = 0; i < roundHandicaps.length; i++) {
      if (roundHandicaps[i].golfers && roundHandicaps[i].golfers[golferId]) {
        filteredList.push({
          courseName: roundHandicaps[i].courseName,
          roundDate: roundHandicaps[i].roundDate,
          css: roundHandicaps[i].css,
          ...roundHandicaps[i].golfers[golferId]
        });
      }
    }
  }
  return filteredList;
};
