import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Button, HelpBlock, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "components/loader-button/LoaderButton";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import PasswordValidation from "components/password-validation/PasswordValidation";
import { upperRegex, numberRegex, emailRegex } from "libs/formUtils";
import { isInAdminGroup } from "libs/awsLib";
import * as API from "API";
import "./Signup.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: "",
      familyName: "",
      email: "",
      password: "",
      stageTwo: false,
      confirmPassword: "",
      confirmationCode: "",
      passwordFocused: false,
      passwordHasUpper: false,
      passwordHasNumber: false,
      passwordHasLength: false,
      newUser: null,
      signupError: false,
      signupConfirmationError: false,
      resendConfirmationLoading: null,
    };
  }

  handleFbLogin = () => {
    this.props.setAuthenticated(true);
  };

  validateForm = () => {
    return (
      this.state.givenName.length > 0 &&
      this.state.familyName.length > 0 &&
      this.state.email.length > 0 &&
      this.state.email.match(emailRegex) &&
      this.state.passwordHasUpper &&
      this.state.passwordHasNumber &&
      this.state.passwordHasLength &&
      this.state.password === this.state.confirmPassword
    );
  };

  validateFormOne = () => {
    return this.state.givenName.length > 0 && this.state.familyName.length > 0;
  };

  validateConfirmationForm = () => {
    return this.state.confirmationCode.length > 0;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.updatePasswordValidation(event);
  };

  updatePasswordValidation = (event) => {
    if (event.target.id === "password") {
      this.setState({
        passwordHasUpper: event.target.value.match(upperRegex),
        passwordHasNumber: event.target.value.match(numberRegex),
        passwordHasLength: event.target.value.length >= 8,
      });
    }
  };

  focusPassword = (event) => {
    let errors = { ...this.state.errors };
    errors.password = null;
    this.setState({
      passwordFocused: true,
      errors: errors,
      signupError: false,
    });
  };

  blurPassword = (event) => {
    let errors = { ...this.state.errors };
    errors.password =
      this.state.password.length > 0 &&
      (!this.state.passwordHasLength ||
        !this.state.passwordHasUpper ||
        !this.state.passwordHasNumber)
        ? "error"
        : null;
    errors.confirmPassword =
      this.state.confirmPassword !== "" &&
      this.state.password !== this.state.confirmPassword
        ? "error"
        : null;
    this.setState({
      passwordFocused: false,
      errors: errors,
    });
  };

  focusPasswordConfirmation = (event) => {
    let errors = { ...this.state.errors };
    errors.confirmPassword = null;
    this.setState({
      errors: errors,
      signupError: false,
    });
  };

  blurPasswordConfirmation = (event) => {
    let errors = { ...this.state.errors };
    errors.confirmPassword =
      this.state.password !== this.state.confirmPassword ? "error" : null;
    this.setState({
      errors: errors,
    });
  };

  focusEmail = (event) => {
    let errors = { ...this.state.errors };
    errors.email = null;
    this.setState({
      errors: errors,
      signupError: false,
    });
  };

  blurEmail = (event) => {
    let errors = { ...this.state.errors };
    errors.email =
      this.state.email.length > 0 && !this.state.email.match(emailRegex)
        ? "error"
        : null;
    this.setState({
      errors: errors,
    });
  };

  goToEmailAndPassword = (event) => {
    this.setState({
      stageTwo: true,
    });
  };

  goToName = (event) => {
    this.setState({
      stageTwo: false,
    });
  };

  handleConfirmationCodeFocus = (event) => {
    this.setState({
      signupConfirmationError: false,
    });
  };

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          given_name: this.state.givenName,
          family_name: this.state.familyName,
        },
      });
      this.setState({
        newUser: newUser,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        signupError: e.message ? e.message : e,
      });
    }
  };

  handleConfirmationSubmit = async (event) => {
    this.setState({ isLoading: true });
    try {
      await Auth.confirmSignUp(
        this.state.email.toLowerCase(),
        this.state.confirmationCode
      );
      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);

      const isAdmin = await isInAdminGroup();
      this.props.setAdmin(isAdmin);

      const currentUser = await API.getGolferByEmail(
        this.state.email.toLowerCase()
      );
      this.props.setCurrentUser(
        currentUser && currentUser.length > 0 ? currentUser[0] : null
      );

      this.props.setAuthenticated(true);

      this.props.history.push("/");
    } catch (e) {
      this.setState({
        isLoading: false,
        signupConfirmationError: e.message ? e.message : e,
      });
    }
  };

  handleResendConfirmation = async (event) => {
    this.setState({ resendConfirmationLoading: "Sending..." });
    try {
      await Auth.resendSignUp(this.state.email.toLowerCase());
      this.setState({ resendConfirmationLoading: "Check your email." });
      setTimeout(
        () => this.setState({ resendConfirmationLoading: null }),
        3000
      );
    } catch (e) {
      this.setState({ signupConfirmationError: e.message ? e.message : e });
    }
  };

  renderConfirmationForm = () => (
    <form>
      {this.state.signupConfirmationError ? <FormAlert formError={this.state.signupConfirmationError} /> : <FormAlert formInformation="We've sent a code to your email. If you don't see it, please check your spam folder." />}
      <FormRow
        autoFocus
        controlId="confirmationCode"
        type="tel"
        label="Confirmation Code"
        value={this.state.confirmationCode}
        onChange={this.handleChange}
        onFocus={this.handleConfirmationCodeFocus}
        help={<HelpBlock>Please check your email for the code.</HelpBlock>}
      />
      <LoaderButton
        block
        bsSize="large"
        bsStyle="primary"
        disabled={!this.validateConfirmationForm()}
        onClick={this.handleConfirmationSubmit.bind(this)}
        isLoading={this.state.isLoading}
        text="Verify"
        loadingText="Verifying…"
      />
      <FormGroup className="confirmation-code-link">
        {this.state.resendConfirmationLoading ? (
          this.state.resendConfirmationLoading
        ) : (
          <Button
            bsStyle="link"
            role="button"
            onClick={this.handleResendConfirmation}
          >
            Resend confirmation code
          </Button>
        )}
      </FormGroup>
    </form>
  );

  renderForm = () => (
    <form>
      <FormAlert formError={this.state.signupError} />
      <h3>Sign Up</h3>
      {!this.state.stageTwo ? (
        <div className="name-details">
          <FormRow
            autoFocus
            controlId="givenName"
            label="Given Name"
            value={this.state.givenName}
            onChange={this.handleChange}
          />
          <FormRow
            controlId="familyName"
            label="Family Name"
            value={this.state.familyName}
            onChange={this.handleChange}
          />
          <Button
            block
            bsSize="large"
            bsStyle="primary"
            disabled={!this.validateFormOne()}
            onClick={this.goToEmailAndPassword}
          >
            Next
          </Button>
        </div>
      ) : (
        <div className="signup-email-password">
          <Button
            bsStyle="link"
            role="button"
            onClick={this.goToName}
            className="back-to-step-one"
          >
            &lt; back to step one
          </Button>
          <FormRow
            controlId="email"
            errors={this.state.errors}
            label="Email"
            value={this.state.email}
            onChange={this.handleChange}
            onFocus={this.focusEmail}
            onBlur={this.blurEmail}
            autoComplete="new-email"
            validationMessage="Please enter a valid email."
          />
          <FormRow
            controlId="password"
            errors={this.state.errors}
            label="Password"
            value={this.state.password}
            onChange={this.handleChange}
            onFocus={this.focusPassword}
            onBlur={this.blurPassword}
            autoComplete="new-password"
            type="password"
            validationMessage="Please enter a valid password."
            className={
              this.state.passwordFocused &&
              (!this.state.passwordHasLength ||
                !this.state.passwordHasNumber ||
                !this.state.passwordHasUpper)
                ? "password-incomplete"
                : ""
            }
            passwordValidation={
              <PasswordValidation
                passwordFocused={this.state.passwordFocused}
                passwordHasUpper={this.state.passwordHasUpper}
                passwordHasNumber={this.state.passwordHasNumber}
                passwordHasLength={this.state.passwordHasLength}
              />
            }
          />
          <FormRow
            controlId="confirmPassword"
            errors={this.state.errors}
            label="Confirm Password"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            onFocus={this.focusPasswordConfirmation}
            onBlur={this.blurPasswordConfirmation}
            type="password"
            autoComplete="new-password"
            validationMessage="Passwords do not match."
          />
          <LoaderButton
            block
            bsSize="large"
            bsStyle="primary"
            disabled={!this.validateForm()}
            onClick={this.handleSubmit.bind(this)}
            isLoading={this.state.isLoading}
            text="Signup"
            loadingText="Signing up…"
          />
        </div>
      )}
      <FormGroup className="confirmation-code-link">
        <Link to="/confirm-account">Already have a confirmation code?</Link>
      </FormGroup>
      <FormGroup className="login-link">
        <div>Already registered?</div>
        <Link className="btn btn-block btn-lg btn-default" to="/login">
          Login here
        </Link>
      </FormGroup>
    </form>
  );

  render = () => (
    <div className="Signup">
      {this.state.newUser === null
        ? this.renderForm()
        : this.renderConfirmationForm()}
    </div>
  );
}
