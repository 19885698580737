import { invokeApig } from "../libs/awsLib";

const apiName = "golfer";

// Golfer API
export const golfers = () => invokeApig({ endpoint: apiName, path: "/golfer" });
export const getGolfer = id =>
  id !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/golfer/${id}`
      })
    : {};
export const getGolferByEmail = email =>
  invokeApig({
    endpoint: apiName,
    path: "/golfer",
    queryParams: { email: email }
  });
export const saveGolfer = (id, golfer) =>
  invokeApig({
    endpoint: apiName,
    path: `/golfer/${id}`,
    method: "PUT",
    body: golfer
  });
export const createGolfer = golfer =>
  invokeApig({
    endpoint: apiName,
    path: "/golfer",
    method: "POST",
    body: golfer
  });

export const deleteGolfer = id =>
  invokeApig({
    endpoint: apiName,
    path: `/golfer/${id}`,
    method: "DELETE"
  });

export const golferRounds = id =>
  invokeApig({ endpoint: apiName, path: `/golfer-rounds/${id}` });

export const golferDetails = id => {
  let req = { endpoint: apiName, path: `/golfer-details/${id}` };
  return invokeApig(req);
};

export const annualStats = year => {
  let req = { endpoint: apiName, path: `/year-stats/${year}` };
  return invokeApig(req);
};

export const golferAnnualStats = id => {
  let req = { endpoint: apiName, path: `/golfer-year-stats/${id}` };
  return invokeApig(req);
};
