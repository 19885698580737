import React from "react";
import defaultImg from "img/shakespeare_green.png";
import "./TeamMember.css";

export default props => (
  <div className="col-sm-12 col-lg-6">
    <div className="team-member">
      <img
        className="mx-auto rounded-circle"
        src={props.position.image ? props.position.image : defaultImg}
        alt={props.position.name}
      />
      <h4>{props.position.name}</h4>
      <p className="text-muted">{props.position.role}</p>
      <p className="text-muted">{props.position.desc}</p>
    </div>
  </div>
);
