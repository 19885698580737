import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LeaderBoard.css";

export default props => (
  <Table className="LeaderBoard" striped bordered condensed>
    <thead>
      <tr>
        <th className="place-col">#</th>
        <th className="golfer-col">
          {props.heading ? props.heading : "Golfer"}
        </th>
        <th className="points-col">{props.score ? props.score : "Points"}</th>
      </tr>
    </thead>
    <tbody>
      {[{}].concat(props.data).map(
        (item, idx) =>
          idx !== 0 && ((props.showZeros && item[props.field] !== null) || (!props.showZeros && item[props.field])) ? (
            <tr key={idx}>
              <td>{item.position + (item.tied ? "=" : "")}</td>
              <td>
                {item.position === 1 ? props.overrideFirstIcon ? props.overrideFirstIcon : (
                  <span className="fas fa-trophy gold" />
                ) : item.position === 2 ? (
                  <span className="fas fa-trophy silver" />
                ) : item.position === 3 ? (
                  <span className="fas fa-trophy bronze" />
                ) : idx === props.data.length ? props.overrideLastIcon ? props.overrideLastIcon : (
                  <span className="fas fa-trophy pink" />
                ) : null}
                {props.link && item[props.field] ? (
                  <Link to={props.link.replace(":golferId", item.id)}>
                    {props.renderName
                      ? props.renderName(item)
                      : item.givenName + " " + item.familyName}
                  </Link>
                ) : props.renderName ? (
                  props.renderName(item)
                ) : (
                  item.givenName + " " + item.familyName
                )}
              </td>
              <td>
                {item[props.field] +
                  " " +
                  (item.countBack ? "(" + item.countBack + ")" : "")}
              </td>
            </tr>
          ) : null
      )}
    </tbody>
  </Table>
);
