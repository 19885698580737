import React, { Component } from "react";
import * as API from "API";
import { Glyphicon, Grid, Col, Row, Alert } from "react-bootstrap";
import EntityList from "components/entity-list/EntityList";
import LeaderBoard from "components/leader-board/LeaderBoard";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { COMPETITIONS_CONTENT as PAGE_CONTENT } from "libs/content";
import Photos from "components/photos/Photos";
import greenJacket from "img/green-jacket.png";
import orangeJacket from "img/orange-jacket.png";
import "./CompetitionSummary.css";

class CompetitionSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      compName: "",
      compType: "",
      dateFrom: "",
      dateTo: "",
      teams: [],
      rounds: [],
      individualComp: "individualStableford",
      ryderCup: false,
      excludeRyderCupScoreFromIndividual: false,
      formattedData: {},
      photos: []
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const scoresPromise = API.getScores(this.props.match.params.id);
      const photosPromise = API.getCompetitionPhotos(
        this.props.match.params.id
      );

      const scores = await scoresPromise;
      const photos = await photosPromise;

      const comp = scores.competition,
        rounds = scores.rounds;

      this.props.setLoading(false);

      const competition = {
        id: comp.id || "",
        compName: comp.compName || "",
        compType: comp.compType || "",
        dateFrom: comp.dateFrom || "",
        dateTo: comp.dateTo || "",
        compHidden: comp.compHidden,
        competitionDetails:
          comp.competitionDetails !== null &&
          typeof comp.competitionDetails !== "undefined"
            ? comp.competitionDetails
            : true,
        teams: comp.teams || [],
        rounds: rounds || [],
        individualComp: comp.individualComp || "individualStableford",
        ryderCup: comp.ryderCup || false,
        ryderCupAdvanced: comp.ryderCupAdvanced || false,
        excludeRyderCupScoreFromIndividual:
          comp.excludeRyderCupScoreFromIndividual || false,
        winner: comp.winner,
        runnerUp: comp.runnerUp,
        lastPlace: comp.lastPlace,
        parThreeChampWinner: comp.parThreeChampWinner,
        nearestPinWinner: comp.nearestPinWinner,
        nearestPinIn2Winner: comp.nearestPinIn2Winner,
        longestDriveWinner: comp.longestDriveWinner,
        shitShirtWinner: comp.shitShirtWinner,
        ryderCupWinner: comp.ryderCupWinner,
        ryderCupIndividualWinner: comp.ryderCupIndividualWinner,
        formattedData: scores.formattedData,
        photos: photos
      };
      this.setState(competition);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  setPhotos = photos => {
    this.setState({ photos: photos });
  };

  renderTeamName = team => team.name;

  renderTeamCompName = team => {
    let name = "";
    for (let g of team.golfers) {
      name = name + (g.givenName + " " + g.familyName + ", ");
    }
    return name.substring(0, name.length - 2);
  };

  formattedURL = id => {
    return "/round/summary/" + id;
  };

  displayHeader = round => {
    return round.courseName ? round.courseName : "";
  };

  displayText = round => (
    <span>
      {round.roundDate}{" "}
      {round.complete ? (
        <span className="round-complete text-muted">
          <Glyphicon glyph="ok" className="text-muted" /> Complete
        </span>
      ) : null}
    </span>
  );

  renderIndividualComp = () =>
    this.state.formattedData.stablefordData ? (
      <div>
        <h3>Individual Competition</h3>
        <h4>
          {this.state.individualComp === "individualPoints" ? (
            <Link
              to={
                "/competition/summary/" + this.state.id + "/individual-points"
              }
            >
              Points System
            </Link>
          ) : this.state.individualComp === "individualDropLowRound" ? (
            <Link
              to={"/competition/summary/" + this.state.id + "/drop-low-round"}
            >
              Stableford - Drop Low Round
            </Link>
          ) : this.state.rounds.length > 1 ? (
            <Link to={"/competition/summary/" + this.state.id + "/stableford"}>
              Stableford
            </Link>
          ) : (
            "Stableford"
          )}
        </h4>
        {this.state.individualComp === "individualPoints" ? (
          <div>
            <LeaderBoard
              overrideFirstIcon={<img src={greenJacket} alt={"Green Jacket"} className="jacket-icon" />}
              overrideLastIcon={<img src={orangeJacket} alt={"Orange Jacket"} className="jacket-icon" />}
              showZeros={true}
              data={
                this.state.formattedData[
                  this.state.ryderCup &&
                  !this.state.excludeRyderCupScoreFromIndividual
                    ? "pointsSystemIncRyderCupData"
                    : "pointsSystemData"
                ]
              }
              field={
                this.state.ryderCup &&
                !this.state.excludeRyderCupScoreFromIndividual
                  ? "totalPointsSystemScoreIncRyderCup"
                  : "totalPointsSystemScore"
              }
              link={
                "/competition/summary/" +
                this.state.id +
                "/individual-points/golfer/:golferId"
              }
            />
            <div>
              <h4>What if...</h4>
              <div>
                <Link
                  to={"/competition/summary/" + this.state.id + "/stableford"}
                >
                  Stableford Competion
                </Link>
              </div>
              <div>
                <Link
                  to={
                    "/competition/summary/" + this.state.id + "/drop-low-round"
                  }
                >
                  Stableford Competions (Drop Low Round)
                </Link>
              </div>
            </div>
          </div>
        ) : this.state.individualComp === "individualDropLowRound" ? (
          <LeaderBoard
            overrideFirstIcon={<img src={greenJacket} alt={"Green Jacket"} className="jacket-icon" />}
            overrideLastIcon={<img src={orangeJacket} alt={"Orange Jacket"} className="jacket-icon" />}
            showZeros={true}
            data={this.state.formattedData.dropLowRoundData}
            field="dropLowRoundScore"
            score="Stableford"
            link={
              "/competition/summary/" +
              this.state.id +
              "/drop-low-round/golfer/:golferId"
            }
          />
        ) : (
          <LeaderBoard
            overrideFirstIcon={<img src={greenJacket} alt={"Green Jacket"} className="jacket-icon" />}
            overrideLastIcon={<img src={orangeJacket} alt={"Orange Jacket"} className="jacket-icon" />}
            showZeros={true}
            data={this.state.formattedData.stablefordData}
            field="stablefordScore"
            score="Stableford"
            link={
              this.state.rounds.length > 1
                ? "/competition/summary/" +
                  this.state.id +
                  "/stableford/golfer/:golferId"
                : this.state.rounds[0].fullScoresRecorded
                ? "/round/" + this.state.rounds[0].id + "/scorecard/:golferId"
                : null
            }
          />
        )}
      </div>
    ) : null;

  renderTeamComp = () =>
    !this.state.ryderCup &&
    this.state.formattedData.teamScores &&
    this.state.rounds.length === 1 &&
    this.state.rounds[0].scoringFormat &&
    this.state.rounds[0].fullScoresRecorded ? (
      <div>
        <h3>
          Team Competition
          {this.state.rounds[0].scoringFormat === "TEAM_4BCS"
            ? " - Combined"
            : this.state.rounds[0].scoringFormat === "TEAM_4BBB"
            ? " - Better Ball"
            : this.state.rounds[0].scoringFormat === "TEAM_4BSA"
            ? " - South African"
            : ""}
        </h3>
        <LeaderBoard
          showZeros={true}
          data={this.state.formattedData.teamScores}
          field="teamScore"
          score="Stableford"
          renderName={this.renderTeamCompName}
        />
      </div>
    ) : null;

  renderParThreeChamp = () =>
    this.state.formattedData.parThreeData ? (
      this.state.formattedData.parThreeData.filter(itm => itm.parThreeScore > 0)
        .length > 0 ? (
        <div>
          <h3>
            <Link
              to={"/competition/summary/" + this.state.id + "/par-three-champ"}
            >
              Par Three Champ
            </Link>
          </h3>
          <LeaderBoard
            showZeros={true}
            data={this.state.formattedData.parThreeData}
            field="parThreeScore"
            score="Stableford"
            link={
              "/competition/summary/" +
              this.state.id +
              "/par-three-champ/golfer/:golferId"
            }
          />
        </div>
      ) : (
        <div>
          <h3>Par Three Champ</h3>
          <Alert bsStyle="warning">
            <p>No scores have been entered for individual holes.</p>
          </Alert>
        </div>
      )
    ) : null;

  renderNearestPin = () =>
    this.state.formattedData.hasNearestPin &&
    this.state.formattedData.nearestPinData ? (
      <div>
        <h3>Nearest The Pin</h3>
        <LeaderBoard
          data={this.state.formattedData.nearestPinData}
          field="nearestPin"
          score="Wins"
        />
      </div>
    ) : null;

  renderNearestPinIn2 = () =>
    this.state.formattedData.hasNearestPinIn2 &&
    this.state.formattedData.nearestPinIn2Data ? (
      <div>
        <h3>Nearest The Pin In Two</h3>
        <LeaderBoard
          data={this.state.formattedData.nearestPinIn2Data}
          field="nearestPinIn2"
          score="Wins"
        />
      </div>
    ) : null;

  renderLongestDrive = () =>
    this.state.formattedData.hasLongestDrive &&
    this.state.formattedData.longestDriveData ? (
      <div>
        <h3>Longest Drive</h3>
        <LeaderBoard
          data={this.state.formattedData.longestDriveData}
          field="longestDrive"
          score="Wins"
        />
      </div>
    ) : null;

  renderShitShirtComp = () =>
    this.state.shitShirtWinner ? (
      <div>
        <h3>Shit Shirt Competition</h3>
        <div className="shit-shirt-winner">
          <span className="fas fa-tshirt gold" />
          <span className="shit-shirt-winner-text">{this.state.shitShirtWinner.givenName + " " + this.state.shitShirtWinner.familyName}</span>
        </div>
      </div>
    ) : null;

  renderRyderCup = () =>
    this.state.ryderCup ? (
      <div>
        <h3>
          <Link to={"/competition/summary/" + this.state.id + "/ryder-cup"}>
            SGS Trophy
          </Link>
        </h3>
        <LeaderBoard
          showZeros={true}
          data={this.state.formattedData.ryderCupData}
          field="ryderCupTotal"
          renderName={this.renderTeamName}
          heading={"Team"}
        />
      </div>
    ) : null;

  renderRyderCupIndividual = () =>
    this.state.ryderCup && this.state.ryderCupAdvanced? (
      <div>
        <h3>
          <Link
            to={
              "/competition/summary/" + this.state.id + "/ryder-cup-individual"
            }
          >
            SGS Trophy Individual
          </Link>
        </h3>
        <LeaderBoard
          data={this.state.formattedData.ryderCupIndividualData}
          field="ryderCupTotal"
          link={
            "/competition/summary/" +
            this.state.id +
            "/ryder-cup-individual/golfer/:golferId"
          }
        />
      </div>
    ) : null;

  renderMainCompetitionSummary = () => (
    <div>
      <h2>
        <span className={PAGE_CONTENT.HEADING_ICON + " nav-header-icon"} />
        {this.state.compName}
      </h2>
      {this.state.competitionDetails ? (
        <div>
          {this.state.photos.length > 0 ? (
            <div className="gallery-link">
              <Link className="hidden-lg" to={"/gallery/" + this.state.id}>
                View Gallery
              </Link>
            </div>
          ) : null}
          {this.state.photos.length === 0 && this.state.id ? (
            <Photos
              folder="competition"
              competitionId={this.state.id}
              showUpload={
                this.props.currentUser && this.props.currentUser.memberStatus
                  ? true
                  : false
              }
              emptyCallback={this.setPhotos}
              preloadedImages={this.state.photos}
            />
          ) : null}
          {this.state.compHidden ? (
            <h4>No peeking, results are a secret at the moment!</h4>
          ) : (
            <div>
              <div className="text-muted">
                Results taken from {this.state.rounds.length} rounds
              </div>
              {this.renderRyderCup()}
              {this.renderIndividualComp()}
              {this.renderShitShirtComp()}
              {this.renderTeamComp()}
              {this.renderParThreeChamp()}
              {this.renderRyderCupIndividual()}
              {this.renderNearestPin()}
              {this.renderNearestPinIn2()}
              {this.renderLongestDrive()}
              <h3>Rounds</h3>
              <EntityList
                {...this.props}
                isLoading={this.state.isLoading}
                listItems={this.state.rounds}
                type="round"
                header={this.displayHeader}
                text={this.displayText}
                hideAdd={true}
                formattedURL={this.formattedURL}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {this.state.winner && this.state.winner.id ? (
            <div>
              <div className="comp-winner">
                <span className="fas fa-trophy gold" /> Winner -{" "}
                <span className="name">
                  {this.state.winner.givenName +
                    " " +
                    this.state.winner.familyName}
                </span>
                .
              </div>
              {this.state.runnerUp ? (
                <div className="comp-winner">
                  <span className="fas fa-trophy silver" /> Runner Up -{" "}
                  <span className="name">
                    {this.state.runnerUp.givenName +
                      " " +
                      this.state.runnerUp.familyName}
                  </span>
                  .
                </div>
              ) : null}
              {this.state.lastPlace ? (
                <div className="comp-winner">
                  <span className="fas fa-trophy pink" /> Turkey Trophy -{" "}
                  <span className="name">
                    {this.state.lastPlace.givenName +
                      " " +
                      this.state.lastPlace.familyName}
                  </span>
                  .
                </div>
              ) : null}
              {this.state.parThreeChampWinner ? (
                <div className="comp-winner">
                  Par Three Champ -{" "}
                  <span className="name">
                    {this.state.parThreeChampWinner.givenName +
                      " " +
                      this.state.parThreeChampWinner.familyName}
                  </span>
                  .
                </div>
              ) : null}
              {this.state.nearestPinWinner ? (
                <div className="comp-winner">
                  Nearest The Pin -{" "}
                  <span className="name">
                    {this.state.nearestPinWinner.givenName +
                      " " +
                      this.state.nearestPinWinner.familyName}
                  </span>
                  .
                </div>
              ) : null}
              {this.state.nearestPinIn2Winner ? (
                <div className="comp-winner">
                  Nearest The Pin In Two -{" "}
                  <span className="name">
                    {this.state.nearestPinIn2Winner.givenName +
                      " " +
                      this.state.nearestPinIn2Winner.familyName}
                  </span>
                  .
                </div>
              ) : null}
              {this.state.longestDriveWinner ? (
                <div className="comp-winner">
                  Longest Drive -{" "}
                  <span className="name">
                    {this.state.longestDriveWinner.givenName +
                      " " +
                      this.state.longestDriveWinner.familyName}
                  </span>
                  .
                </div>
              ) : null}
              {this.state.ryderCupWinner &&
              this.state.ryderCupWinner.length > 0 ? (
                <div className="comp-winner">
                SGS Trophy -{" "}
                  <span className="name">{this.state.ryderCupWinner}</span>.
                </div>
              ) : null}
              {this.state.ryderCupIndividualWinner ? (
                <div className="comp-winner">
                  SGS Trophy Individual -{" "}
                  <span className="name">
                    {this.state.ryderCupIndividualWinner.givenName +
                      " " +
                      this.state.ryderCupIndividualWinner.familyName}
                  </span>
                  .
                </div>
              ) : null}
            </div>
          ) : null}
          <br />
          <div className="text-muted">
            No teams, rounds or individual scores were kept for this
            competition.
          </div>
        </div>
      )}
    </div>
  );

  render = () => (
    <div className="CompetitionSummary">
      {this.state.photos.length > 0 && this.state.id ? (
        <Grid className="comp-summary-grid">
          <Row className="show-grid">
            <Col md={12} lg={6}>
              {this.renderMainCompetitionSummary()}
            </Col>
            <Col lg={6} className="gallery-col" mdHidden>
              <Photos
                folder="competition"
                competitionId={this.state.id}
                showUpload={
                  this.props.currentUser && this.props.currentUser.memberStatus
                    ? true
                    : false
                }
                delayShow={true}
                emptyCallback={this.setPhotos}
                preloadedImages={this.state.photos}
              />
            </Col>
          </Row>
        </Grid>
      ) : (
        this.renderMainCompetitionSummary()
      )}
    </div>
  );
}

export default withRouter(CompetitionSummary);
