import React, { Component } from "react";
import PageHeader from "components/page-header/PageHeader";
import TeamMember from "components/team-member/TeamMember";
import * as API from "API";
import { COMMITTEE_CONTENT as PAGE_CONTENT } from "libs/content";

export default class Committee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commitee: []
    };
  }
  componentDidMount = async () => {
    try {
      this.props.setLoading(true);

      const committee = await API.content("committee");

      this.props.setLoading(false);

      const state = {
        committee: committee
      };
      this.setState(state);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };
  render = () => {
    return (
      <div id="committee">
        <PageHeader
          heading={PAGE_CONTENT.HEADING}
          subHeading={PAGE_CONTENT.SUB_HEADING}
          icon={PAGE_CONTENT.HEADING_ICON}
        />
        <div className="row">
          {[{}]
            .concat(this.state.committee)
            .map((item, idx) =>
              idx !== 0 && item ? (
                <TeamMember key={idx} position={item} />
              ) : null
            )}
        </div>
      </div>
    );
  };
}
