import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  FormGroup,
  Button,
  HelpBlock,
  Nav,
  NavItem,
  Checkbox,
  ButtonGroup,
  Radio,
  Alert
} from "react-bootstrap";
import LoaderButton from "components/loader-button/LoaderButton";
import LoaderLink from "components/loader-link/LoaderLink";
import GolferList from "components/golfer-list/GolferList";
import SelectGolfers from "components/select-golfers/SelectGolfers";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import DropdownOptions from "components/dropdown-options/DropdownOptions";
import FinalScores from "components/final-scores/FinalScores";
import GolferScores from "components/golfer-scores/GolferScores";
import { lettersRegex, numbersOnlyRegex, validateInput } from "libs/formUtils";
import Photos from "components/photos/Photos";
import "./Round.css";
import { golferAnnualStats } from "api/golferAPI";

class Round extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roundDate: new Date().toISOString().slice(0, 10),
      competition: null,
      course: null,
      courseTee: "",
      ladiesTee: "",
      groups: [],
      competitions: [],
      scoringFormat: "",
      individualScoringFormat: "",
      golfers: [],
      courses: [],
      tees: [],
      selectedGroup: null,
      fullScoresRecorded: true,
      showHandicapChanges: true,
      competitionTypes: [
        {
          id: "TEAM_SINGLES_MATCHPLAY",
          name: "Singles Matchplay",
          maxGroupSize: 2,
          minGroupSize: 2
        },
        {
          id: "TEAM_4BCS",
          name: "4 Ball Combined Score",
          maxGroupSize: 4,
          minGroupSize: 4
        },
        {
          id: "TEAM_4BBB",
          name: "4 Ball Better Ball",
          maxGroupSize: 4,
          minGroupSize: 4
        },
        {
          id: "TEAM_4BSA",
          name: "4 Ball South African",
          maxGroupSize: 4,
          minGroupSize: 4
        },
        {
          id: "TEAM_4BTS",
          name: "Texas Scramble",
          maxGroupSize: 4,
          minGroupSize: 4,
          actualMaxSize: 2
        }
        // { name: "Pairs - Greensomes", maxGroupSize: 4, minGroupSize: 4 },
        // { name: "Pairs - Texas Scramble", maxGroupSize: 4, minGroupSize: 4 },
        // {
        //   name: "Team Stableford - Best Two Per Hole",
        //   maxGroupSize: 4,
        //   minGroupSize: 2
        // },
        // {
        //   name: "Team Stableford - Combined Team Score",
        //   maxGroupSize: 4,
        //   minGroupSize: 2
        // }
      ]
    };
  }

  componentWillMount = async () => {
    if (!this.props.isAdmin) {
      this.props.history.replace(
        "/round/summary/" + this.props.match.params.id
      );
    }
  };

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const resultsPromise = API.getRound(this.props.match.params.id);

      const competitionsPromise = API.competitionsLite();
      const golfersPromise = API.golfers();
      const coursePromise = API.coursesLite();
      const results = await resultsPromise;
      const competitions = await competitionsPromise;
      const golfers = await golfersPromise;
      const courses = await coursePromise;
      this.props.setLoading(false);

      // if (results.course) {
      //   let matchingCourse = courses.filter(
      //     course => course.id === results.course.id
      //   )[0];
      //   results.course.tees.red = matchingCourse.tees.red;
      // }

      const round = {
        id: results.id || "",
        roundDate: results.roundDate || new Date().toISOString().slice(0, 10),
        competition: results.competition || null,
        course: results.course || null,
        courseTee: results.courseTee || "",
        ladiesTee: results.ladiesTee || "",
        nearestPin: results.nearestPin || null,
        nearestPinIn2: results.nearestPinIn2 || null,
        longestDrive: results.longestDrive || null,
        scoringFormat: results.scoringFormat || "",
        individualScoringFormat: results.individualScoringFormat || "",
        groups: results.groups || [],
        competitions: competitions || [],
        golfers: golfers || [],
        courses: courses || [],
        tees: results.course ? Object.keys(results.course.tees) : [],
        fullScoresRecorded:
          results.fullScoresRecorded != null
            ? results.fullScoresRecorded
            : true,
        showHandicapChanges: results.showHandicapChanges || false,
        selectedGroup: null
      };

      this.setState(round);
    } catch (e) {
      this.props.setError(true);
    }
  };

  validateForm = () => {
    return (
      this.state.course &&
      this.state.courseTee.length > 0 &&
      !isNaN(Date.parse(this.state.roundDate)) &&
      this.areGroupNumbersValid() &&
      (!this.state.competition ||
        this.state.competition.individualComp !== "individualPoints" ||
        this.state.individualScoringFormat.length > 0) &&
      (!this.state.competition ||
        (this.state.competition.individualComp !== "individualPoints" &&
          !this.state.competition.ryderCup) ||
        this.state.scoringFormat.length > 0)
    );
  };

  validateResultsForm = () => {
    for (let group of this.state.groups) {
      for (let golfer of group.golfers) {
        if (!golfer.stablefordPoints || !golfer.handicap) {
          return false;
        }
      }
    }
    return this.validateForm();
  };

  removeGroup = event => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this group?"
    );
    if (!confirmed) {
      return;
    }
    const groups = [...this.state.groups],
      group = { ...this.state.selectedGroup },
      groupIndex = groups
        .map(selectedGroup => selectedGroup.nbr)
        .indexOf(group.nbr);

    groups.splice(groupIndex, 1);
    this.setState({
      groups: groups,
      selectedGroup: null,
      dirty: true
    });
  };

  confirmGroup = event => {
    let groups = [...this.state.groups],
      group = { ...this.state.selectedGroup },
      groupIndex = groups
        .map(selectedGroup => selectedGroup.nbr)
        .indexOf(group.nbr);


    if (groupIndex === -1) {
      groups.push(group);
    } else {
      groups[groupIndex] = group;
    }

    if(this.state.scoringFormat === "TEAM_4BTS"){
      let teamA = [], teamB = [];
      for (let teamGolfer of this.state.competition.teams[0].golfers){
        for(let golfer of group.golfers){
          if(golfer.id === teamGolfer.id){
            teamA.push(golfer);
          }
        }
      }
      for (let teamGolfer of this.state.competition.teams[1].golfers){
        for(let golfer of group.golfers){
          if(golfer.id === teamGolfer.id){
            teamB.push(golfer);
          }
        }
      }
      group.golfers = [this.mergeGolfer(teamA), this.mergeGolfer(teamB)];
    }

    for (let i = group.golfers.length - 1; i >= 0; --i) {
      if (
        !group.golfers[i].id ||
        this.state.golfers.filter(golfer => golfer.id === group.golfers[i].id)
          .length === -1
      ) {
        group.golfers.splice(i, 1);
      }
    }
    this.setState({
      groups: groups,
      selectedGroup: null,
      dirty: true
    });
  };

  isGolferSelected = golfer => {
    return (
      this.state.selectedGroup.golfers.filter(
        selectedGolfer => selectedGolfer.id === golfer.id
      ).length === 1
    );
  };

  addGroup = event => {
    const newGroup = {
      nbr: this.state.groups.length,
      name: "Group " + (this.state.groups.length + 1),
      golfers: []
    };
    this.setState({
      selectedGroup: newGroup,
      dirty: true
    });
    window.scrollTo(0, 0);
  };

  editGroup = group => {
    const groupForEdit = { ...group };
    groupForEdit.golfers = [...groupForEdit.golfers];
    this.setState({ selectedGroup: groupForEdit });
    window.scrollTo(0, 0);
  };

  mergeGolfer = (pair) => {
    return {
      email: pair[0].email+"::"+pair[1].email,
      familyName: "",
      gender: pair[0].gender+"::"+pair[1].gender,
      ghost: pair[0].ghost+"::"+pair[1].ghost,
      givenName: (pair[0].givenName + " " + pair[0].familyName + " & " + pair[1].givenName + " " + pair[1].familyName),
      handicap: Math.round((Math.min(pair[0].handicap, pair[1].handicap)*0.35) + (Math.max(pair[0].handicap, pair[1].handicap)*0.15)),
      id: pair[0].id+"::"+pair[1].id,
      pk: "golfer",
      golferA: pair[0],
      golferB: pair[1]
    }
  }

  toggleGolfer = golfer => {
    const group = { ...this.state.selectedGroup },
      golferIndex = group.golfers
        .map(selectedGolfer => selectedGolfer.id)
        .indexOf(golfer.id);

        if (golferIndex === -1) {
          group.golfers.push(golfer);
        } else {
          group.golfers.splice(golferIndex, 1);
        }
   
    this.setState({
      selectedGroup: group,
      dirty: true
    });
  };

  handleCourseChange = async event => {
    this.setState({
      loadingCourse: true
    });

    const courseId = event.target.value,
      course = await API.getCourse(courseId),
      tees = course ? Object.keys(course.tees) : [];

    this.setState({
      course: course,
      courseTee: tees.length > 0 ? tees[0] : "",
      tees: tees,
      dirty: true,
      loadingCourse: false
    });
  };

  handleCompetitionChange = async event => {
    this.setState({
      loadingCompetition: true
    });

    const competitionId = event.target.value,
      competition = await API.getCompetition(competitionId);

    this.setState({
      competition: competition,
      dirty: true,
      loadingCompetition: false
    });
  };

  handleGolferChange = event => {
    const golferId = event.target.value,
      golfer = golferId
        ? this.state.golfers.filter(golfer => golfer.id === golferId)[0]
        : null;

    this.setState({
      dirty: true,
      [event.target.id]: { ...golfer }
    });
  };

  handleFullScoresRecordedChange = event => {
    this.setState({
      dirty: true,
      fullScoresRecorded:
        (event.target.id === "completeScores" && event.target.value === "on") ||
        (event.target.id === "finalScoresOnly" && event.target.value !== "on")
    });

    if (event.target.id === "completeScores" && event.target.value === "on") {
      this.state.groups.forEach(group => {
        group.golfers.forEach(golfer => {
          this.confirmGolferScores(golfer);
        });
      });
    }
  };

  handleGolferRoundChange = (group, golfer, value, hcp) => {
    const grp = { ...group },
      groups = [...this.state.groups],
      groupIndex = groups
        .map(selectedGroup => selectedGroup.nbr)
        .indexOf(grp.nbr);

    const golferIndex = grp.golfers
      .map(selectedGolfer => selectedGolfer.id)
      .indexOf(golfer.id);

    const updatedGolfer = { ...grp.golfers[golferIndex] };
    const field = hcp ? "handicap" : "stablefordPoints";

    updatedGolfer[field] = value;

    grp.golfers[golferIndex] = updatedGolfer;

    groups[groupIndex] = grp;

    this.setState({
      groups: groups,
      dirty: true
    });

    if (hcp) {
      this.confirmGolferScores(updatedGolfer);
    }
  };

  handleGolferRoundBlur = (id, golfer, hcp) => {
    let errors = { ...this.state.errors };
    const field = hcp ? "handicap" : "stablefordPoints";
    errors[id] =
      !golfer[field] ||
      isNaN(golfer[field]) ||
      golfer[field] * 1 < 0 ||
      golfer[field] * 1 > 60
        ? "error"
        : null;

    this.setState({
      errors: errors
    });
  };

  handleChange = event => {
    this.setState({
      dirty: true,
      [event.target.id]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    });
  };

  handleTeamScoringFormatChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
      groups: [],
      dirty: true
    });
  };

  handleFocus = event => {
    let errors = { ...this.state.errors };
    errors[event.target.id] = null;
    this.setState({
      errors: errors,
      formError: false
    });
  };

  handleBlur = event => {
    const regex = event.target.attributes.getNamedItem("data-regex"),
      stateProp = this.state[event.target.id];
    let errors = { ...this.state.errors };

    errors[event.target.id] = !validateInput(
      regex ? regex.value : null,
      !stateProp ? "" : typeof stateProp === "object" ? stateProp.id : stateProp
    )
      ? "error"
      : null;

    this.setState({
      errors: errors
    });
  };

  handleDateBlur = event => {
    const val = Date.parse(event.target.value);
    let errors = { ...this.state.errors };

    errors[event.target.id] =
      (event.target.id === "dateTo" && event.target.value === "") || !isNaN(val)
        ? null
        : "error";

    this.setState({
      errors: errors
    });
  };

  prepareRound = () => ({
    roundDate: this.state.roundDate,
    competition: this.cleanCompetition(this.state.competition),
    course: this.state.course,
    courseTee: this.state.courseTee,
    ladiesTee: this.state.ladiesTee,
    nearestPin: this.state.nearestPin,
    nearestPinIn2: this.state.nearestPinIn2,
    longestDrive: this.state.longestDrive,
    straightestDrive: this.state.straightestDrive,
    scoringFormat: this.state.competition ? this.state.scoringFormat : null,
    individualScoringFormat: this.state.competition
      ? this.state.individualScoringFormat
      : null,
    groups: this.cleanGroups(this.state.groups),
    fullScoresRecorded: this.state.fullScoresRecorded,
    showHandicapChanges: this.state.showHandicapChanges,
    complete: this.validateForm() && this.validateResultsForm()
  });

  cleanCompetition = competition => {
    if (competition === null || competition.teams == null) {
      return null;
    }
    let clonedComp = (({
      competitionDetails,
      compName,
      compType,
      compYear,
      dateFrom,
      dateTo,
      id,
      individualComp,
      teams
    }) => ({
      competitionDetails,
      compName,
      compType,
      compYear,
      dateFrom,
      dateTo,
      id,
      individualComp,
      teams
    }))(competition);

    let updatedTeams = clonedComp.teams.map(({ golfers, name, nbr }) => ({
      golfers,
      name,
      nbr
    }));

    for (let team of updatedTeams) {
      team.golfers = team.golfers.map(
        ({ email, familyName, gender, ghost, givenName, handicap, id }) => ({
          email,
          familyName,
          gender,
          ghost,
          givenName,
          handicap,
          id
        })
      );
    }

    clonedComp.teams = updatedTeams;

    return clonedComp;
  };

  cleanGroups = groups => {
    return groups.map(({ golfers, name, nbr }) => ({ golfers, name, nbr }));
  };

  handleSubmit = async event => {
    let round = this.prepareRound();
    round.fullSave = true;
    this.setState({ isLoading: true });
    try {
      if (!this.state.id || this.state.id === "new") {
        await API.createRound(round);
      } else {
        await API.saveRound(this.state.id, round);
      }

      if (round.complete && this.state.id) {
        this.props.history.push("/round/summary/" + this.state.id);
      } else {
        this.props.history.goBack();
      }
    } catch (e) {
      this.setState({ isLoading: false, formError: e.message });
    }
  };

  intermediarySave = async next => {
    let round = this.prepareRound();
    let savedRound = null;
    const loadingKey = next ? "isNextLoading" : "isLoading";
    this.setState({ [loadingKey]: true });
    try {
      if (!this.state.id || this.state.id === "new") {
        savedRound = await API.createRound(round);
      } else {
        savedRound = await API.saveRound(this.state.id, round);
      }
      this.setState({
        [loadingKey]: false,
        id: savedRound.id || this.state.id
      });
    } catch (e) {
      this.setState({ [loadingKey]: false, formError: e.message });
    }
  };

  handleDelete = async event => {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this round?"
    );
    if (!confirmed) {
      return;
    }
    this.setState({ isDeleting: true });
    try {
      await API.deleteRound(this.state.id);
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isDeleting: false, formError: e.message });
    }
  };

  alreadyInGroup = golferId => {
    for (let grp of this.state.groups) {
      for (let g of grp.golfers) {
        if (g.id === golferId) {
          return grp.name;
        }
      }
    }
    return false;
  };

  areGroupNumbersValid = () => {
    for (let group of this.state.groups) {
      let valid = this.isGroupValid(group);
      if (!valid) {
        return false;
      }
    }
    return this.state.groups.length > 0;
  };

  isGroupValid = group => {
    if (this.state.scoringFormat) {
      let compType = this.state.competitionTypes.filter(
        competitionType => competitionType.id === this.state.scoringFormat
      )[0];

      if(compType.actualMaxSize){
        if(group.golfers.length < compType.actualMaxSize ||
          group.golfers.length > compType.actualMaxSize){
            return false;
        }
      }
      else{
        if(group.golfers.length < compType.minGroupSize ||
          group.golfers.length > compType.maxGroupSize){
            return false;
        }
      }
    }
    return true;
  };

  isGroupConfirmDisabled = () => {
    let count = this.state.selectedGroup.golfers.length,
      group = this.state.selectedGroup,
      compType = null;

      if (this.state.scoringFormat) {
        compType = this.state.competitionTypes.filter(
          competitionType => competitionType.id === this.state.scoringFormat
        )[0];

        if(compType.minGroupSize === 4 && compType.maxGroupSize){
          let teamA = [], teamB = [];
          for (let teamGolfer of this.state.competition.teams[0].golfers){
            for(let golfer of group.golfers){
              if(golfer.id === teamGolfer.id){
                teamA.push(golfer);
              }
            }
          }
          for (let teamGolfer of this.state.competition.teams[1].golfers){
            for(let golfer of group.golfers){
              if(golfer.id === teamGolfer.id){
                teamB.push(golfer);
              }
            }
          }
          if(teamA.length !== 2 || teamB.length !== 2){
            return true;
          }
        }
        else {
          return count < compType.minGroupSize || count > compType.maxGroupSize;
        }
      }
      
    return false;
  };

  getGroupNumbersText = () => {
    let text = "",
      compType = null;
    if (this.state.scoringFormat) {
      compType = this.state.competitionTypes.filter(
        competitionType => competitionType.id === this.state.scoringFormat
      )[0];
      if (compType.minGroupSize === compType.maxGroupSize) {
        text = "Please select " + compType.minGroupSize + " golfers.";
      } else {
        text =
          "Please select between " +
          compType.minGroupSize +
          " and " +
          compType.maxGroupSize +
          " golfers.";
      }
    }
    return text;
  };

  editGolferScores = golfer => {
    let course = JSON.parse(JSON.stringify(this.state.course));
    this.setState({
      selectedGolfer: golfer,
      courseBackup: course,
      dirty: true
    });
  };

  confirmGolferScores = (golfer = this.state.selectedGolfer) => {
    let groups = [...this.state.groups],
      course = { ...this.state.course },
      tee =
        golfer.gender === "F" &&
        this.state.ladiesTee !== null &&
        this.state.ladiesTee !== ""
          ? course.tees[this.state.ladiesTee]
          : course.tees[this.state.courseTee];

    let golferScore = null;
    tee.holes.forEach(hole => {
      golferScore = hole[golfer.id] || {};
      golferScore.pts = this.calculateStablefordPoints(
        golferScore.score,
        golfer.handicap,
        hole.par,
        hole.si
      );
    });

    groups.forEach(group => {
      let golferIndex = group.golfers
        .map(arrayGolfer => arrayGolfer.id)
        .indexOf(golfer.id);
      if (golferIndex !== -1) {
        golfer = group.golfers[golferIndex];
        golfer.stablefordPoints = this.getScoreTotals(golfer, "pts", "total");
      }
    });

    this.setState({
      course: course,
      groups: groups,
      dirty: true
    });
  };

  saveGolferScores = async () => {
    this.confirmGolferScores();

    let scores = [],
      handicap;
    for (let tee in this.state.course.tees) {
      for (let hole of this.state.course.tees[tee].holes) {
        for (let golferId in hole) {
          if (golferId === this.state.selectedGolfer.id) {
            let h = { ...hole[golferId] };
            h.par = hole.par;
            h.si = hole.si;
            h.len = hole.len;
            scores.push(h);
          }
        }
      }
    }
    for (let group of this.state.groups) {
      for (let golfer of group.golfers) {
        if (golfer.id === this.state.selectedGolfer.id) {
          handicap = golfer.handicap;
          break;
        }
      }
    }

    let golferScores = {
      golferId: this.state.selectedGolfer.id,
      handicap: handicap,
      roundDate: this.state.roundDate,
      courseId: this.state.course.id,
      courseName: this.state.course.courseName,
      scores: scores
    };

    await API.saveRoundScore(this.state.id, golferScores);

    this.setState({
      selectedGolfer: null,
      dirty: true
    });
  };

  next = async () => {
    await this.intermediarySave(true);
    this.setState({ results: true });
    window.scrollTo(0, 0);
  };

  calculateStablefordPoints = (score, handicap, par, si) => {
    let pts = 0;
    if (score) {
      pts = 2 + par * 1 - score;
      handicap = Math.round(handicap);
      pts = pts + (handicap / si >= 1 ? 1 : 0);

      while (handicap > 18) {
        handicap = handicap - 18;
        pts = pts + (handicap / si >= 1 ? 1 : 0);
      }
      pts = Math.max(pts, 0);
    }
    return pts;
  };

  handleGolferScoreChange = (nbr, golfer, value) => {
    let course = { ...this.state.course };
    let tee =
      golfer.gender === "F" &&
      this.state.ladiesTee !== null &&
      this.state.ladiesTee !== ""
        ? course.tees[this.state.ladiesTee]
        : course.tees[this.state.courseTee];
    const holeIndex = tee.holes.map(arrayHole => arrayHole.nbr).indexOf(nbr),
      blob = value === "0";

    let hole = tee.holes[holeIndex];
    let golferHoleScore = { ...hole[golfer.id] };
    golferHoleScore.score = blob ? hole.par : value;

    golferHoleScore.pts = this.calculateStablefordPoints(
      golferHoleScore.score,
      golfer.handicap,
      hole.par,
      hole.si
    );

    if (blob) {
      golferHoleScore.score = hole.par * 1 + golferHoleScore.pts * 1 + "";
      golferHoleScore.pts = "0";
    }

    hole[golfer.id] = golferHoleScore;

    this.setState({
      course: course,
      dirty: true
    });
  };

  handleGolferScoreBlur = (nbr, golfer, value) => {
    let errors = { ...this.state.errors };
    const tee =
      golfer.gender === "F" &&
      this.state.ladiesTee !== null &&
      this.state.ladiesTee !== ""
        ? this.state.course.tees[this.state.ladiesTee]
        : this.state.course.tees[this.state.courseTee];
    const holeIndex = tee.holes.map(arrayHole => arrayHole.nbr).indexOf(nbr);
    const hole = tee.holes[holeIndex];
    const golferHoleScore = { ...hole[golfer.id] };
    golferHoleScore.score = golferHoleScore.score ? golferHoleScore.score : "";
    const valid =
      golferHoleScore.score.match(numbersOnlyRegex) &&
      golferHoleScore.score * 1 > 0 &&
      golferHoleScore.score * 1 <= 20;

    errors["hole_" + nbr] = valid ? null : "error";
    this.setState({
      errors: errors
    });
  };

  areGolferScoresInvalid = () => {
    let golfer = this.state.selectedGolfer;
    const tee =
      golfer.gender === "F" &&
      this.state.ladiesTee !== null &&
      this.state.ladiesTee !== ""
        ? this.state.course.tees[this.state.ladiesTee]
        : this.state.course.tees[this.state.courseTee];
    let golferScore = null;
    for (let hole of tee.holes) {
      golferScore = hole[golfer.id];
      if (
        !golferScore ||
        !golferScore.score.match(numbersOnlyRegex) ||
        golferScore.score * 1 <= 0 ||
        golferScore.score * 1 > 20
      ) {
        return true;
      }
    }
    return false;
  };

  getScoreTotals = (golfer, type, field) => {
    const tee =
      golfer.gender === "F" &&
      this.state.ladiesTee !== null &&
      this.state.ladiesTee !== ""
        ? this.state.course.tees[this.state.ladiesTee]
        : this.state.course.tees[this.state.courseTee];
    let front = 0,
      back = 0,
      golferScore = null;
    tee.holes.forEach(hole => {
      golferScore = hole[golfer.id];
      if (golferScore && golferScore[type]) {
        if (hole.nbr <= 9) {
          front = front + (golferScore[type] ? golferScore[type] * 1 : 0);
        } else {
          back = back + (golferScore[type] ? golferScore[type] * 1 : 0);
        }
      }
    });

    const values = {
      total: front + back === 0 ? null : front + back,
      front: front === 0 ? null : front,
      back: back === 0 ? null : back
    };

    return values[field];
  };

  getGolfersPlaying = () => {
    let golfers = [];
    for (let group of this.state.groups) {
      for (let golfer of group.golfers) {
        golfers.push(golfer);
      }
    }
    return golfers.sort(function(a, b) {
      if (a.givenName + a.familyName < b.givenName + b.familyName) return -1;
      if (a.givenName + a.familyName > b.givenName + b.familyName) return 1;
      return 0;
    });
  };

  getIndividualCompetitionTypes = () => {
    const sizeOfTeam1 = this.state.competition.teams[0].golfers.length;

    let individualCompetitionTypes = [
      {
        id: "INDIVIDUAL_POINTS_IN_FIELD",
        name: "Ranked across the whole field"
      }
    ];

    if (sizeOfTeam1 === 4 || sizeOfTeam1 === 6 || sizeOfTeam1 === 8) {
      individualCompetitionTypes.push({
        id: "INDIVIDUAL_POINTS_IN_GROUP",
        name: "Ranked within each group"
      });
    }
    return individualCompetitionTypes;
  };

  renderSelectedGroup = () => (
    <SelectGolfers
      formError={this.state.formError}
      homeClick={e =>
        this.setState({
          selectedGroup: null
        })
      }
      homeName={this.state.course.courseName}
      name={this.state.selectedGroup.name}
      type="group"
      confirm={this.confirmGroup}
      confirmDisabled={this.isGroupConfirmDisabled()}
      showDelete={
        this.state.groups.filter(
          existingGroup => existingGroup.nbr === this.state.selectedGroup.nbr
        ).length === 1
          ? true
          : null
      }
      remove={this.removeGroup}
      golfers={this.state.golfers}
      toggleGolfer={this.toggleGolfer}
      isGolferSelected={this.isGolferSelected}
      competition={this.state.competition}
      alreadyIn={this.alreadyInGroup}
    >
      <HelpBlock>{this.getGroupNumbersText()}</HelpBlock>
    </SelectGolfers>
  );

  renderSelectedGolfer = () => (
    <GolferScores
      formError={this.state.formError}
      homeClick={e =>
        this.setState({
          selectedGolfer: null,
          course: this.state.courseBackup,
          courseBackup: null
        })
      }
      homeName="Results"
      name={
        this.state.selectedGolfer.givenName +
        " " +
        this.state.selectedGolfer.familyName
      }
      golfer={this.state.selectedGolfer}
      course={this.state.course}
      tee={
        this.state.selectedGolfer.gender === "F" &&
        this.state.ladiesTee !== null &&
        this.state.ladiesTee !== ""
          ? this.state.course.tees[this.state.ladiesTee]
          : this.state.course.tees[this.state.courseTee]
      }
      onChange={this.handleGolferScoreChange}
      onBlur={this.handleGolferScoreBlur}
      errors={this.state.errors}
      totals={this.getScoreTotals}
      confirm={this.saveGolferScores}
      disabled={this.areGolferScoresInvalid()}
      isLoading={this.state.isLoading}
    />
  );

  renderInitialForm = () => (
    <div>
      <Nav bsStyle="pills" activeKey={1}>
        <NavItem eventKey={1}>1. Setup</NavItem>
        <NavItem eventKey={2}>2. Results</NavItem>
      </Nav>
      <form>
        <FormAlert formError={this.state.formError} />
        <h3>Round Details</h3>
        {!this.state.dirty &&
        this.state.id &&
        this.validateForm() &&
        this.validateResultsForm() ? (
          <FormGroup>
            <Link to={"/round/summary/" + this.state.id}>
              View Round Summary
            </Link>
          </FormGroup>
        ) : null}
        <FormRow
          controlId="roundDate"
          errors={this.state.errors}
          label="Date"
          value={this.state.roundDate}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleDateBlur}
          validationMessage="Please enter a valid date."
          mask="1111-11-11"
          placeholder="YYYY-MM-DD"
        />
        {this.state.loadingCourse ? (
          <h4 className="inline-loading">Loading Course Details...</h4>
        ) : (
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="course"
            errors={this.state.errors}
            label="Course"
            value={this.state.course ? this.state.course.id : ""}
            onChange={this.handleCourseChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            regex={lettersRegex}
            validationMessage="Please select a course."
          >
            <DropdownOptions
              listItems={this.state.courses}
              renderItemText={item => item.courseName}
            />
          </FormRow>
        )}

        <FormRow
          className="capitalize"
          componentClass="select"
          placeholder="select"
          controlId="courseTee"
          errors={this.state.errors}
          label="Mens Tee"
          value={this.state.courseTee}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          regex={lettersRegex}
          validationMessage="Please select a tee."
        >
          {this.state.tees.length === 0 ? (
            <option value="">Pick a course first</option>
          ) : (
            <DropdownOptions
              listItems={this.state.tees}
              renderItemText={item => item}
            />
          )}
        </FormRow>
        <div className="hide">
          <FormRow
            className="capitalize"
            componentClass="select"
            placeholder="select"
            controlId="ladiesTee"
            errors={this.state.errors}
            label="Ladies Tee (Optional)"
            value={this.state.ladiesTee}
            onChange={this.handleChange}
          >
            {this.state.tees.length === 0 ? (
              <option value="">Pick a course first</option>
            ) : (
              <DropdownOptions
                listItems={this.state.tees}
                renderItemText={item => item}
              />
            )}
          </FormRow>
        </div>
        <Checkbox
            id="showHandicapChanges"
            checked={this.state.showHandicapChanges}
            onChange={this.handleChange}
          >
            Show Handicap Changes
        </Checkbox>
        <hr />
        <h3>Competition</h3>
        {this.state.loadingCompetition ? (
          <h4 className="inline-loading">Loading Competition Details...</h4>
        ) : (
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="competition"
            label="Competition (Optional)"
            value={this.state.competition ? this.state.competition.id : ""}
            onChange={this.handleCompetitionChange}
          >
            <DropdownOptions
              listItems={this.state.competitions}
              renderItemText={item => item.compName}
            />
          </FormRow>
        )}
        {this.state.competition ? (
          this.state.competition.individualComp === "individualPoints" ? (
            <FormRow
              componentClass="select"
              placeholder="select"
              controlId="individualScoringFormat"
              label="Individual Scoring Format"
              value={this.state.individualScoringFormat}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              errors={this.state.errors}
              onBlur={this.handleBlur}
              regex={lettersRegex}
              validationMessage="Please select a scoring format."
            >
              <DropdownOptions
                listItems={this.getIndividualCompetitionTypes()}
                renderItemText={item => item.name}
              />
            </FormRow>
          ) : this.state.competition.individualComp ===
            "individualStableford" ? (
            <HelpBlock className="comp-types-help">
              Individual Stableford scores will count towards the overall
              competition.
            </HelpBlock>
          ) : this.state.competition.individualComp ===
            "individualDropLowRound" ? (
            <HelpBlock className="comp-types-help">
              Individual Stableford scores will count towards the overall
              competition but the lowest round can be dropped.
            </HelpBlock>
          ) : null
        ) : null}
        {this.state.competition &&
        this.state.competition.individualComp === "individualPoints" &&
        this.state.individualScoringFormat === "INDIVIDUAL_POINTS_IN_FIELD" ? (
          <Alert bsStyle="info">
            <h4>Info</h4>
            <p>
              In a group of 12 - 1st = 14pts, 2nd = 12pts, 3rd = 10pts, 4th =
              9pts....11th = 2pts, 12th = 1pt.
            </p>
          </Alert>
        ) : this.state.competition &&
          this.state.competition.individualComp === "individualPoints" &&
          this.state.individualScoringFormat ===
            "INDIVIDUAL_POINTS_IN_GROUP" ? (
          <Alert bsStyle="info">
            <h4>Info</h4>
            <p>
              In each 4 ball - 1st = 5pts, 2nd = 3pts, 3rd = 2pts, 4th = 1pt.
            </p>
          </Alert>
        ) : null}
        {this.state.competition ? (
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="scoringFormat"
            label={
              this.state.competition.individualComp !== "individualPoints" &&
              !this.state.competition.ryderCup
                ? "Team Scoring Format (Optional)"
                : "Team Scoring Format"
            }
            value={this.state.scoringFormat}
            onChange={this.handleTeamScoringFormatChange}
            onFocus={
              this.state.competition.individualComp !== "individualPoints" &&
              !this.state.competition.ryderCup
                ? null
                : this.handleFocus
            }
            errors={
              this.state.competition.individualComp !== "individualPoints" &&
              !this.state.competition.ryderCup
                ? null
                : this.state.errors
            }
            onBlur={this.handleBlur}
            regex={
              this.state.competition.individualComp !== "individualPoints" &&
              !this.state.competition.ryderCup
                ? null
                : lettersRegex
            }
            validationMessage={
              this.state.competition.individualComp !== "individualPoints" &&
              !this.state.competition.ryderCup
                ? null
                : "Please select a scoring format."
            }
          >
            <DropdownOptions
              listItems={this.state.competitionTypes}
              renderItemText={item => item.name}
            />
          </FormRow>
        ) : null}
        {this.state.competition ? (
          <HelpBlock className="comp-types-help">
            The following are calculated automatically:
            <ul className="comp-types-list">
              <li>Par 3 Champ</li>
              {this.state.competition.ryderCup && this.state.scoringFormat ? (
                <li>SGS Trophy</li>
              ) : null}
            </ul>
          </HelpBlock>
        ) : null}
        <hr />
        {this.state.course ? <h3>Groups</h3> : null}
        <GolferList
          listItems={this.state.groups}
          edit={this.editGroup}
          isGroupValid={this.isGroupValid}
          validationMessage="Invalid number of golfers for the selected competition type."
          addClick={this.addGroup}
          type="group"
        />
        <ButtonGroup>
          <LoaderButton
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            onClick={this.handleSubmit.bind(this)}
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            bsSize="large"
            bsStyle="primary"
            disabled={!this.validateForm()}
            isLoading={this.state.isNextLoading}
            onClick={this.next}
            text="Next"
            loadingText="Loading..."
          />
        </ButtonGroup>
        {this.state.id && this.state.id !== "new" ? (
          <LoaderLink
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete Round"
            loadingText="Deleting…"
          />
        ) : null}
      </form>
    </div>
  );

  renderResultsForm = () => (
    <div>
      <Nav bsStyle="pills" activeKey={2}>
        <NavItem
          className="clickable-nav"
          eventKey={1}
          onClick={e => this.setState({ results: false })}
        >
          1. Setup
        </NavItem>
        <NavItem eventKey={2}>2. Results</NavItem>
      </Nav>
      <form>
        <FormAlert formError={this.state.formError} />
        <h3>Scores</h3>
        {this.state.id ? (
          <Photos
            roundId={this.state.id}
            showUpload={true}
            folder="scorecards"
          />
        ) : null}

        <FormGroup>
          <Radio
            id="completeScores"
            name="radioGroup"
            checked={this.state.fullScoresRecorded}
            onChange={this.handleFullScoresRecordedChange}
          >
            Complete Hole by Hole Scores
          </Radio>
          <Radio
            id="finalScoresOnly"
            name="radioGroup"
            checked={!this.state.fullScoresRecorded}
            onChange={this.handleFullScoresRecordedChange}
          >
            Just Final Stableford Scores
          </Radio>
        </FormGroup>
        <FinalScores
          editGolferScores={this.editGolferScores}
          completeScores={this.state.fullScoresRecorded}
          groups={this.state.groups}
          errors={this.state.errors}
          onChange={this.handleGolferRoundChange}
          onFocus={this.handleFocus}
          onBlur={this.handleGolferRoundBlur}
        />
        <h3>Nearest &amp; Longest</h3>
        <FormRow
          componentClass="select"
          placeholder="select"
          controlId="nearestPin"
          label="Nearest The Pin (Optional)"
          value={this.state.nearestPin ? this.state.nearestPin.id : ""}
          onChange={this.handleGolferChange}
        >
          <DropdownOptions
            listItems={this.getGolfersPlaying()}
            renderItemText={item => item.givenName + " " + item.familyName}
          />
        </FormRow>
        <FormRow
          componentClass="select"
          placeholder="select"
          controlId="nearestPinIn2"
          label="Nearest The Pin In Two (Optional)"
          value={this.state.nearestPinIn2 ? this.state.nearestPinIn2.id : ""}
          onChange={this.handleGolferChange}
        >
          <DropdownOptions
            listItems={this.getGolfersPlaying()}
            renderItemText={item => item.givenName + " " + item.familyName}
          />
        </FormRow>
        <FormRow
          componentClass="select"
          placeholder="select"
          controlId="longestDrive"
          label="Longest Drive (Optional)"
          value={this.state.longestDrive ? this.state.longestDrive.id : ""}
          onChange={this.handleGolferChange}
        >
          <DropdownOptions
            listItems={this.getGolfersPlaying()}
            renderItemText={item => item.givenName + " " + item.familyName}
          />
        </FormRow>
        <ButtonGroup>
          <Button
            bsSize="large"
            onClick={e => {
              this.setState({ results: false });
              window.scrollTo(0, 0);
            }}
          >
            Back
          </Button>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateResultsForm()}
            onClick={this.handleSubmit.bind(this)}
            isLoading={this.state.isLoading}
            text="Save Round"
            loadingText="Saving…"
          />
        </ButtonGroup>
        {this.state.id && this.state.id !== "new" ? (
          <LoaderLink
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete Round"
            loadingText="Deleting…"
          />
        ) : null}
      </form>
    </div>
  );

  render = () => (
    <div className="Round">
      {this.state.results
        ? this.state.selectedGolfer
          ? this.renderSelectedGolfer()
          : this.renderResultsForm()
        : this.state.selectedGroup
        ? this.renderSelectedGroup()
        : this.renderInitialForm()}
    </div>
  );
}

export default withRouter(Round);
