import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HonoursBoard.css";

export default props => (
  <div className="HonoursBoard">
    {props.compTypeName ? <h3>{props.icon ? props.icon:null}{props.compTypeName}</h3> : props.compType ? <h3>{props.icon ? props.icon:null}{props.compType}</h3> : <br />}
    <Table striped bordered condensed>
      <thead>
        <tr>
          <th className={props.overrideWinner ? "year-single-col" : "year-col"}>Year</th>
          <th className={props.overrideWinner ? "name-single-col" : "name-col"}>Winner</th>
          {!props.overrideWinner ?
            <th className="name-col">Runner Up</th>
            : null }
        </tr>
      </thead>
      <tbody>
        {[{}]
          .concat(props.comps.filter(comp => comp.compType === props.compType))
          .map(
            (item, idx) =>
              idx !== 0 && (item.winner && item.winner.id && !props.overrideWinner) || (props.overrideWinner && item[props.overrideWinner]) ? (
                <tr key={idx}>
                  <td>
                    <Link to={"/competition/summary/" + item.id}>
                      {item.dateFrom.substring(0, 4)}
                    </Link>
                  </td>
                  <td>
                    {props.overrideWinner ? 
                      item[props.overrideWinner].givenName + " " + item[props.overrideWinner].familyName 
                      : item.winner.givenName + " " + item.winner.familyName}
                  </td>
                  {!props.overrideWinner ?
                    <td>
                      {item.runnerUp && item.runnerUp.id
                        ? item.runnerUp.givenName + " " + item.runnerUp.familyName
                        : null}
                    </td>
                  : null }
                </tr>
              ) : null
          )}
      </tbody>
    </Table>
  </div>
);
