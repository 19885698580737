import React from "react";
import { Table } from "react-bootstrap";
import "./HandicapChange.css";

export default props => (
  <Table className="HandicapChange" striped bordered condensed>
    <thead>
      <tr>
        <th className="golfer-col">
          {props.heading ? props.heading : "Golfer"}
        </th>
        <th className="handicap-col">
          <span className="hidden-lg">H-cap</span>
          <span className="hidden-md hidden-sm hidden-xs">Handicap</span>
        </th>
        <th className="adj-col">
          <span className="hidden-lg">Adj.</span>
          <span className="hidden-md hidden-sm hidden-xs">Adjustment</span>
        </th>
        <th className="win-loss-col">
          <span className="hidden-lg">1st / 2nd Adj.</span>
          <span className="hidden-md hidden-sm hidden-xs">
            First / Second Adjustment
          </span>
        </th>
        <th className="new-handicap-col">
          <span className="hidden-lg">New H-cap</span>
          <span className="hidden-md hidden-sm hidden-xs">New Handicap</span>
        </th>
      </tr>
    </thead>
    <tbody>
      {[{}].concat(props.data).map(
        (item, idx) =>
          idx !== 0 && item[props.field] ? (
            <tr key={idx}>
              <td>
                {props.renderName
                  ? props.renderName(item)
                  : item.givenName + " " + item.familyName}
              </td>
              <td>{props.golferHandicapData[item.id].handicap}</td>
              <td>{props.golferHandicapData[item.id].handicapChange}</td>
              <td>{props.golferHandicapData[item.id].handicapChangeWinLast}</td>
              <td>
                <span className="new-handicap">
                  {props.golferHandicapData[item.id].newHandicap}
                </span>{" "}
                <span
                  className={
                    props.golferHandicapData[item.id].newHandicap >
                    props.golferHandicapData[item.id].handicap
                      ? "fas fa-arrow-up"
                      : props.golferHandicapData[item.id].newHandicap <
                        props.golferHandicapData[item.id].handicap
                        ? "fas fa-arrow-down"
                        : "fas fa-arrow-right"
                  }
                />
              </td>
            </tr>
          ) : null
      )}
    </tbody>
  </Table>
);
