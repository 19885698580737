import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./CompetitionRules.css";

class CompetitionRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: ""
    };
  }
  componentDidMount = async () => {
    try {
      var state = {
        year: this.props.match.params.year
      };
      this.setState(state);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  get2022Rules = () => {
    return (
      <div>
        <div>
          <h2>SGS 2022 Format & Scoring</h2>
          <h4>There are a few changes this year, so pay attention...</h4>
          <div className="rules-content">
            <div>Changes introduced this year are firstly aimed at creating extra competitions (and prizes) that work better over 2 rounds. Secondly, because the rules are so complex, you're unlikely to have a clue what's going on, which may add some mystery to proceedings.</div>

            <div>Key changes include:</div>
            <ul>
              <li>
                Orange and Green Jackets back to being 2 round competitions. 
              </li>
              <li>A new points system for the SGS Trophy.</li>
              <li>Additional competitions for SGS Trophy Individual, Par Three Chanp, Longest Drive, Nearest the Pin and Nearest the Pin in 2.</li>
              <li>We are also playing in 3 balls at times. We'll note 4 ball pairings down on paper and scoring will be done across these 4 ball groups. You won't know the result of matches until after the round is complete and you know how your partner has done.</li>
            </ul>
            <div>Full details for each competition are outlined below.</div>
          </div>
          <hr />
          <h4>Individual Competition</h4>
          <div className="rules-content">
            <div>
              The Green and Orange Jackets will be contested over 2 rounds with combined stableford scores used to determine places. 
              <br /> <br />In the event of a tie, the player with the better score from round 2 will finish higher. If scores are still tied, the player with the better score from the back 9 of round 2 will finish higher.
            </div>
          </div>
          <hr />
          <h4>SGS Trophy</h4>
          <div className="rules-content">
            <div>The team competition format will be as follows:</div>
            <ul>
              <li>
                Round 1 - 3 x 4 ball combined matches. 
              </li>
              <li>Round 2 - 3 x 4 ball better ball matches.</li>
            </ul>
            <div>
              In each match, points will be awarded to teams as follows:
            </div>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th>Front / Back / Overall</th>
                  <th>Win Points</th>
                  <th>Half Points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Front nine</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Back nine</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Overall</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
              </tbody>
            </Table>
            <div>
              Additionally, pairs will have
              their combined scores ranked and points will be awarded to their
              team as follows:
            </div>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1st</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>2nd</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>3rd</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>4th</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>5th</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>6th</td>
                  <td>1</td>
                </tr>
              </tbody>
            </Table>
            <div>
              Lastly, a single SGS Trophy team point is earned each round by the
              winners of:
            </div>
            <ul>
              <li>Nearest the pin.</li>
              <li>Nearest the pin in two.</li>
              <li>Longest drive.</li>
            </ul>
          </div>
          <hr />
          <h4>SGS Trophy Individual</h4>
          <div className="rules-content">
            <div>
              The most points contributed to the SGS Trophy by an individual.
            </div>
          </div>
          <hr />
          <h4>Par Three Champ</h4>
          <div className="rules-content">
            <div>
              Total points on all the par threes across the 2 rounds. No joker
              holes.
            </div>
          </div>
          <hr />
          <h4>Nearest the pin, Nearest the pin in two and Longest drive</h4>
          <div className="rules-content">
            <div>Prizes for each of these will be awarded after each round.</div>
          </div>
        </div>
      </div>
    );
  };
  render = () => {
    return (
      <section className="bg-light CompetitionRules">
        <div>
          <form>
            {this.state.year === "2022" ? (
              this.get2022Rules()
            ) : (
              <h3>No rules to display for this year and competition.</h3>
            )}
          </form>
        </div>
      </section>
    );
  };
}

export default withRouter(CompetitionRules);
