import React, { Component } from "react";
import * as API from "API";
import PageHeader from "components/page-header/PageHeader";
import GolferListDetail from "components/golfer-list-detail/GolferListDetail";
import { HANDICAPS_CONTENT as PAGE_CONTENT } from "libs/content";

export default class Handicaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      golfers: [],
      drillDownLink: "/stats/:golferId"
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      let results = await API.golfers();
      this.props.setLoading(false);

      results = results.sort(function(a, b) {
        if (a.memberStatus && !b.memberStatus) return -1;
        if (!a.memberStatus && b.memberStatus) return 1;
        if (a.givenName + a.familyName < b.givenName + b.familyName) return -1;
        if (a.givenName + a.familyName > b.givenName + b.familyName) return 1;
        return 0;
      });

      this.setState({ golfers: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  render = () => (
    <div className="Handicaps">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <GolferListDetail
        golfers={this.state.golfers}
        drillDownLink={this.state.drillDownLink}
      />
    </div>
  );
}
