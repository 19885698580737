import React, { Component } from "react";
import * as API from "API";
import LeaderBoard from "components/leader-board/LeaderBoard";
import HandicapChange from "components/handicap-change/HandicapChange";
import TeamScoresForRound from "components/team-scores-for-round/TeamScoresForRound";
import { Table, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { getOrdinalSuffix } from "libs/formUtils.js";
import { ROUNDS_CONTENT as PAGE_CONTENT } from "libs/content";
import Photos from "components/photos/Photos";
import "./RoundSummary.css";

class RoundSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roundDate: "",
      course: {},
      courseTee: "",
      scoringFormat: "",
      individualScoringFormat: "",
      groups: [],
      fullScoresRecorded: true,
      formattedData: {},
      selectedGroup: null
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);

      const scoresPromise = API.getScores(null, this.props.match.params.id);
      const roundHandicapPromise = API.getHandicap(this.props.match.params.id);

      const scores = await scoresPromise;
      const roundHandicap = await roundHandicapPromise;

      const comp = scores.competition,
        rd = scores.rounds[0];

      this.props.setLoading(false);
      const round = {
        id: rd.id || "",
        roundDate: rd.roundDate || "",
        competition: comp || null,
        course: rd.course || null,
        courseTee: rd.courseTee || "",
        nearestPin: rd.nearestPin || null,
        nearestPinIn2: rd.nearestPinIn2 || null,
        longestDrive: rd.longestDrive || null,
        scoringFormat: rd.scoringFormat || "",
        showHandicapChanges: rd.showHandicapChanges || false,
        individualScoringFormat: rd.individualScoringFormat || "",
        roundHandicap: roundHandicap,
        individualComp:
          comp && comp.individualComp
            ? comp.individualComp
            : "individualStableford",
        groups: rd.groups || [],
        fullScoresRecorded:
          rd.fullScoresRecorded != null ? rd.fullScoresRecorded : true,
        formattedData: scores.formattedData,
        selectedGroup: null
      };
      this.setState(round);
    } catch (e) {
      this.props.setError(true);
    }
  };

  renderTeamName = team => team.name;

  getRoundMatchArray = () => {
    const groups = this.state.groups;
    let matchArray = [];
    for (let group of groups) {
      let descRow = { hasV: true },
        pointsRow = {},
        resultRow = {},
        placeRow = {},
        bufferRow = { buffer: true };
      const aTeam = group.groupA,
        bTeam = group.groupB;

      const aGolfer1 = aTeam[aTeam.golfer1],
        bGolfer1 = bTeam[bTeam.golfer1];
      const aGolfer2 = aTeam.golfer2 ? aTeam[aTeam.golfer2] : null,
        bGolfer2 = bTeam.golfer2 ? bTeam[bTeam.golfer2] : null;

      descRow.a =
        aGolfer1.givenName +
        " " +
        aGolfer1.familyName +
        (aGolfer2
          ? " & " + aGolfer2.givenName + " " + aGolfer2.familyName
          : "");

      descRow.b =
        bGolfer1.givenName +
        " " +
        bGolfer1.familyName +
        (bGolfer2
          ? " & " + bGolfer2.givenName + " " + bGolfer2.familyName
          : "");

          if(this.state.competition.ryderCup && this.state.competition.ryderCupAdvanced){
            pointsRow.a =
            aTeam.front +
            " + " +
            aTeam.back +
            " + " +
            aTeam.overall +
            " = " +
            aTeam.matchPoints +
            "pts";
    
          pointsRow.b =
            bTeam.front +
            " + " +
            bTeam.back +
            " + " +
            bTeam.overall +
            " = " +
            bTeam.matchPoints +
            "pts";

            resultRow.a = aTeam.matchResult;
            resultRow.b = bTeam.matchResult;

            resultRow.aResult =
              aTeam.overall === 2 ? "winner" : aTeam.overall === 0 ? "loser" : "draw";
            resultRow.bResult =
              bTeam.overall === 2 ? "winner" : bTeam.overall === 0 ? "loser" : "draw";

              descRow.aResult = resultRow.aResult;
              descRow.bResult = resultRow.bResult;
              pointsRow.aResult = resultRow.aResult;
              pointsRow.bResult = resultRow.bResult;
          }
          else{
            pointsRow.a =
            aTeam.matchPoints +
            "pts";
    
          pointsRow.b =
            bTeam.matchPoints +
            "pts";

            resultRow.a = aTeam.matchResult;
            resultRow.b = bTeam.matchResult;

            resultRow.aResult =
              aTeam.overall === 1 ? "winner" : aTeam.overall === 0 ? "loser" : "draw";
            resultRow.bResult =
              bTeam.overall === 1 ? "winner" : bTeam.overall === 0 ? "loser" : "draw";

              descRow.aResult = resultRow.aResult;
              descRow.bResult = resultRow.bResult;
              pointsRow.aResult = resultRow.aResult;
              pointsRow.bResult = resultRow.bResult;
          }

      placeRow.a =
        aTeam.placePoints +
        "pts (" +
        getOrdinalSuffix(aTeam.position) +
        " - " +
        aTeam.totalStableford +
        " Stableford pts)";

      placeRow.b =
        bTeam.placePoints +
        "pts (" +
        getOrdinalSuffix(bTeam.position) +
        " - " +
        bTeam.totalStableford +
        " Stableford pts)";

      matchArray.push(bufferRow);
      matchArray.push(descRow);
      matchArray.push(pointsRow);
      matchArray.push(resultRow);
      if (aTeam.placePoints && bTeam.placePoints) {
        matchArray.push(placeRow);
      }
    }
    return matchArray;
  };

  renderIndividualComp = () =>
    this.state.formattedData.stablefordData ? (
      <div>
        <h3>Individual Competition</h3>
        {this.state.individualComp === "individualPoints" ? (
          <div>
            <h4>
              {this.state.competition ? (
                <Link
                  to={
                    "/competition/summary/" +
                    this.state.competition.id +
                    "/individual-points/round/" +
                    this.state.id
                  }
                >
                  Points System
                </Link>
              ) : (
                "Points System"
              )}
            </h4>
            <LeaderBoard
              showZeros={true}
              data={
                this.state.formattedData[
                  this.state.competition &&
                  this.state.competition.ryderCup &&
                  !this.state.competition.excludeRyderCupScoreFromIndividual
                    ? "pointsSystemIncRyderCupData"
                    : "pointsSystemData"
                ]
              }
              field={
                this.state.competition &&
                this.state.competition.ryderCup &&
                !this.state.competition.excludeRyderCupScoreFromIndividual
                  ? "totalPointsSystemScoreIncRyderCup"
                  : "totalPointsSystemScore"
              }
              link={
                this.state.competition
                  ? "/competition/summary/" +
                    this.state.competition.id +
                    "/individual-points/golfer/:golferId/round/" +
                    this.state.id
                  : null
              }
            />
            <h4>Stableford</h4>
            {this.state.formattedData.stablefordData.filter(
              itm => itm.stablefordScore > 0
            ).length > 0 ? (
              <LeaderBoard
              showZeros={true}
              data={this.state.formattedData.stablefordData}
              field="stablefordScore"
              score="Stableford"
              link={
                this.state.fullScoresRecorded
                  ? "/round/" + this.state.id + "/scorecard/:golferId"
                  : null
              }
            />
            ) : (
              <Alert bsStyle="warning">
                <p>No scores have been entered for individuals.</p>
              </Alert>
            )}
          </div>
        ) : (
          <div>
            <h4>Stableford</h4>
                {this.state.formattedData.stablefordData.filter(
              itm => itm.stablefordScore > 0
            ).length > 0 ? (
              <LeaderBoard
                  showZeros={true}
                  data={this.state.formattedData.stablefordData}
                  field="stablefordScore"
                  score="Stableford"
                  link={
                    this.state.fullScoresRecorded
                      ? "/round/" + this.state.id + "/scorecard/:golferId"
                      : null
                  }
                />
            ) : (
              <Alert bsStyle="warning">
                <p>No scores have been entered for individuals.</p>
              </Alert>
            )} 
          </div>
        )}
        {this.state.roundHandicap && this.state.showHandicapChanges ? (
          <div>
            <h4>
              Handicap Adjustments - (CSS = {this.state.roundHandicap.css}) - <Link
                  to={"/handicap-rules"}>
                  Handicap Rules
                </Link>
            </h4>
            <HandicapChange
              data={this.state.formattedData.stablefordData}
              golferHandicapData={this.state.roundHandicap.golfers}
              field="stablefordScore"
            />
          </div>
        ) : null}
      </div>
    ) : null;

  renderParThreeChamp = () =>
    this.state.competition && this.state.formattedData.parThreeData ? (
      <div>
        <h3>Par Three Scores</h3>
        {this.state.formattedData.parThreeData.filter(
          itm => itm.parThreeScore > 0
        ).length > 0 ? (
          <LeaderBoard
            showZeros={true}
            data={this.state.formattedData.parThreeData}
            field="parThreeScore"
            score="Stableford"
          />
        ) : (
          <Alert bsStyle="warning">
            <p>No scores have been entered for individuals.</p>
          </Alert>
        )}
      </div>
    ) : null;

  renderLongestAndNearest = () =>
    this.state.nearestPin ||
    this.state.nearestPinIn2 ||
    this.state.longestDrive ? (
      <div>
        <h3>Nearest &amp; Longest</h3>
        <Table striped bordered condensed>
          <tbody>
            {this.state.nearestPin ? (
              <tr>
                <td className="nearest-longest-cell">Nearest The Pin</td>
                <td className="nearest-longest-cell">
                  {this.state.nearestPin.givenName +
                    " " +
                    this.state.nearestPin.familyName}
                </td>
              </tr>
            ) : null}
            {this.state.nearestPinIn2 ? (
              <tr>
                <td className="nearest-longest-cell">Nearest The Pin In 2</td>
                <td className="nearest-longest-cell">
                  {this.state.nearestPinIn2.givenName +
                    " " +
                    this.state.nearestPinIn2.familyName}
                </td>
              </tr>
            ) : null}
            {this.state.longestDrive ? (
              <tr>
                <td className="nearest-longest-cell">Longest Drive</td>
                <td className="nearest-longest-cell">
                  {this.state.longestDrive.givenName +
                    " " +
                    this.state.longestDrive.familyName}
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </div>
    ) : null;

  renderRyderCup = () =>
    this.state.competition && this.state.competition.ryderCup ? (
      <div>
        <h3>
          <Link
            to={
              "/competition/summary/" +
              this.state.competition.id +
              "/ryder-cup/round/" +
              this.state.id
            }
          >
          SGS Trophy
          </Link>
        </h3>
        <LeaderBoard
          showZeros={true}  
          data={this.state.formattedData.ryderCupData}
          field="ryderCupTotal"
          renderName={this.renderTeamName}
          heading={"Team"}
        />
        <TeamScoresForRound
          data={this.getRoundMatchArray()}
          teamA={this.state.formattedData.teamAName}
          teamB={this.state.formattedData.teamBName}
        />
      </div>
    ) : null;

  renderRyderCupIndividual = () =>
    this.state.competition && this.state.competition.ryderCup && this.state.competition.ryderCupAdvanced ? (
      <div>
        <h3>
          <Link
            to={
              "/competition/summary/" +
              this.state.competition.id +
              "/ryder-cup-individual/round/" +
              this.state.id
            }
          >
            SGS Trophy Individual
          </Link>
        </h3>
        <LeaderBoard
          data={this.state.formattedData.ryderCupIndividualData}
          field="ryderCupTotal"
          link={
            "/competition/summary/" +
            this.state.competition.id +
            "/ryder-cup-individual/golfer/:golferId/round/" +
            this.state.id
          }
        />
      </div>
    ) : null;

  render = () => (
    <div className="RoundSummary">
      <h2>
        <span className={PAGE_CONTENT.HEADING_ICON + " nav-header-icon"} />
        {this.state.course.courseName}
      </h2>
      <h3>{this.state.roundDate}</h3>
      {this.state.competition && this.state.competition.compHidden ? (
        <div>
          <h4>No peeking, results are a secret at the moment!</h4>
        </div>
      ) : (
        <div>
          {this.renderRyderCup()}
          {this.state.scoringFormat !== "TEAM_4BTS" ? 
            <div>
              {this.renderIndividualComp()}
              {this.renderParThreeChamp()}
              {this.renderLongestAndNearest()}
            </div> 
          : null}
          {this.renderRyderCupIndividual()}
          {this.state.id ? (
            <div>
              <h3>Scorecards</h3>
              <Photos roundId={this.state.id} folder="scorecards" />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default withRouter(RoundSummary);
