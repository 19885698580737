import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import greenJacket from "img/green-jacket.png";
import orangeJacket from "img/orange-jacket.png";
import "./ReigningChampions.css";

export default props => (
  <div className="ReigningChampions">
    <h4>Reigning Champions</h4>
    <Table striped bordered condensed>
      <thead>
        <tr>
          <th className="comp-col">Competition</th>
          <th className="name-col">Winner</th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td>
            <span className={"champ fas fa-trophy gold"} />
            <Link to={"/competition/summary/" + props.comp.id}>
              SGS Trophy
            </Link>
          </td>
          <td>{props.comp.ryderCupSummary[0].ryderCupTotal > props.comp.ryderCupSummary[1].ryderCupTotal 
                ? props.comp.ryderCupSummary[0].name : 
                props.comp.ryderCupSummary[0].ryderCupTotal < props.comp.ryderCupSummary[1].ryderCupTotal 
                ? props.comp.ryderCupSummary[1].name : "Tied"}</td>
        </tr>
        <tr>
          <td>
            <img src={greenJacket} alt={"Green Jacket"} className="jacket-icon" />
            <Link to={"/competition/summary/" + props.comp.id}>
              Green Jacket
            </Link>
          </td>
          <td>{props.comp.winner.givenName + " " + props.comp.winner.familyName}</td>
        </tr>
        <tr>
          <td>
            <img src={orangeJacket} alt={"Orange Jacket"} className="jacket-icon" />
            <Link to={"/competition/summary/" + props.comp.id}>
              Orange Jacket
            </Link>
          </td>
          <td>{props.comp.lastPlace.givenName + " " + props.comp.lastPlace.familyName}</td>
        </tr>
        {props.comp.shitShirtWinner ? 
          <tr>
            <td>
              <span className={"champ fas fa-tshirt "} />
              <Link to={"/competition/summary/" + props.comp.id}>
                Shit Shirt
              </Link>
            </td>
            <td>{props.comp.shitShirtWinner.givenName + " " + props.comp.shitShirtWinner.familyName}</td>
          </tr>
        : null
        }
      </tbody>
    </Table>
  </div>
);
